/**
 * @copyright 2019 @ DigiNet
 * @author CANHTOAN
 * @create 07/20/2020
 * @Example
 */

import React, {Component} from "react";

import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import DateRangeBox from '../../../common/date/date-range';
import withStyles from "@material-ui/core/styles/withStyles";
import * as W38F2001Actions from "../../../../redux/W3X/W38F2001/W38F2001_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {Col, Row, FormGroup} from "react-bootstrap";
import {Combo, TextField, Checkbox} from "../../../common/form-material";
// import DateBoxPicker from "../../../common/form-material/date-box";
import ButtonGeneral from "../../../common/button/button-general";
import ButtonCustom from "../../../common/button/button-custom";
import InlineSVG from "react-inlinesvg";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import moment from 'moment';
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import IconButton from "@material-ui/core/IconButton";
import UserImage from "../../../common/user/user-image";
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    InputLabel,
    Typography
} from "@material-ui/core";
// import W38F2001EmployeeList from "../../../W2X/W25/W38F2001/W38F2001EmployeeList";
import Attachments from "../../../common/attachments/attachments";
import MForm from "../../../common/form-material/form";
import _ from "lodash";
import NumberFormat from 'react-number-format';
import {browserHistory} from "react-router";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Icons from "../../../common/icons/";
import GridActionBar from "../../../grid-container/grid-actionbar";
const styles = theme => ({
    ExpansionPanelDetails: {
        padding: 0
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    textField: {
        '& .MuiFormHelperText-root': {
            marginTop: 0,
            position: "relative",
            top: 0
        }
    },
    panel: {
        transition: 'all .4s'
    },
    panelForm: {
        transition: 'all .5s',
        opacity: 1
    },
    hiddenOpacity: {
        opacity: 0
    },
    close: {
        width: 0,
        height: 0,
    },
    open: {
        height: '100%',
        overflowY: 'auto',
    },
    dateRanger: {
        backgroundColor: '#ebebeb'
    }
});

class W38F2001 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataCboTrainingField: {
                rows: [],
                total: 0
            },
            dataTrainingCourse: {
                rows: [],
                total: 0
            },
            error                : {},
            loadingProjects      : false,
            loadingTrainingField : false,
            loadingTrainingCourse: false,
            gridLoading          : false,
            expandedFirst        : false,
            disabled             : false,
            showEmployeeSelect   : false,
            NotJoinTrain         : false,
            formData: {
                TrainingFieldID  : "",
                TrainingCourseID : "",
                IsInternal       : 0,
                ProNumber        : 0,
                ProjectID        : "",
                DepartmentID     : "",
                TeamID           : "",
                TrainingPurpose  : "",
                Content          : "",
                TrainingPeriod   : "",
                TrainingMonthNum : "",
                ProAverageCosts  : "",
                CurrencyID       : "",
                ProCost          : 0,
                ProExchangeRate  : 0,
                ProNote          : "",
                ProCCost         : 0,
            },
            HREmployeeID     : "",
            TrainningEmpName : "",
            DateRangeForm: [{
                startDate: null,
                endDate  : null,
                key      : 'selection'
            }],
            dateFromTo: '',
            dataOldAttachments: [],
            selectedEmployeeID: [],
            uploading         : false,
            formLoading       : false,
            dataDefaults      : null,
        };

        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;
        this.TrainingCourse = null;
        this.fieldJustChanged = [];
        this.filterCboProjects = {
            timer: null,
            skip: 0,
            limit: 50,
            strSearch: ""
        }
        this.filterCboTrainingField = {
            timer: null,
            skip: 0,
            limit: 50,
            strSearch: ""
        }
        this.filterTrainingCourse = {
            timer: null,
            skip: 0,
            limit: 50,
            strSearch: ""
        }
        this.TrainingPurposeRef = React.createRef();
        this.ContentRef = React.createRef();
    }

    loadCboDepartments = () => {
        const param = {
            DivisionID: Config.getDivisionID(),
            CompanyID: "",
            HostID: "",
            FormID: "W38F2000",
            Language: Config.language || '84'
        };
        this.props.generalActions.getCboDepartments(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboTeams = () => {
        const param = {
            DivisionID: Config.getDivisionID(),
            CompanyID: "",
            HostID: "",
            FormID: "W38F2000",
            Language: Config.language || '84'
        };
        this.props.generalActions.getCboTeam(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboProjects = (isReset) => {
        const param = {
            HostID: "",
            FormID: "W38F2000",
            Language: Config.language || '84',
            Mode: 0,
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setLoading('Projects', true);
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.setLoading('Projects', false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCurrency = () => {
        this.props.generalActions.getLoadCurrency({}, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboTrainingField = (isReset) => {
        const { skip, limit, strSearch } = this.filterCboTrainingField;
        const param = {
            DivisionID: Config.getDivisionID(),
            TrainingFieldID: '',
            TrainingCourseID: '',
            Language: Config.language || '84',
            search: strSearch,
            skip: skip,
            limit: limit,
        };
        this.setLoading('TrainingField', true);
        this.props.W38F2001Actions.loadCboTrainingField(param, (error, data) => {
            this.setLoading('TrainingField', false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboTrainingField} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboTrainingField: {
                        rows: isReset ? rows : dataCboTrainingField.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadTrainingCourse = (isReset, ID = '', isFirst) => {
        const { skip, limit, strSearch } = this.filterTrainingCourse;
        const { TrainingFieldID } = this.state.formData;
        const param = {
            DivisionID: Config.getDivisionID(),
            TrainingFieldID: ID || TrainingFieldID?.TrainingFieldID,
            TrainingCourseID: '',
            Language: Config.language || '84',
            search: strSearch,
            skip: skip,
            limit: limit,
        };

        this.setLoading('TrainingCourse', true);
        this.props.W38F2001Actions.loadTrainingCourse(param, (error, data) => {
            this.setLoading('TrainingCourse', false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { formData, dataTrainingCourse } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                if (!isFirst) {
                    formData.TrainingCourseID = {};
                }
                this.setState({
                    dataTrainingCourse: {
                        rows: isReset ? rows : dataTrainingCourse.rows.concat(rows),
                        total: total
                    },
                    formData
                })
            }
        });
    };

    loadTrainingCourseDetail = (TrainingCourseID) => {
        const { TrainingFieldID } = this.state.formData;
        const param = {
            DivisionID: Config.getDivisionID(),
            TrainingFieldID: TrainingFieldID?.TrainingFieldID,
            TrainingCourseID: TrainingCourseID,
            Language: Config.language || '84',
        };
        this.props.W38F2001Actions.loadTrainingCourseDetail(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                let { formData, DateRangeForm, TrainningEmpName } = this.state;
                const FromDate             = data.FromDate         ? data.FromDate.split('/')         : null;
                const ToDate               = data.ToDate           ? data.ToDate.split('/')           : null;

                formData.ProAverageCosts   = data.ProAverageCosts  ? data.ProAverageCosts.toFixed(2)  : formData.ProAverageCosts ;
                formData.ProCost           = data.ProCost          ? data.ProCost.toFixed(2)          : formData.ProCost         ;
                formData.CurrencyID        = data.ProCurrencyID    ? data.ProCurrencyID               : formData.ProCurrencyID   ;
                formData.Content           = data.Content          ? data.Content                     : formData.Content         ;
                formData.TrainingMonthNum  = data.TrainingMonthNum ? data.TrainingMonthNum            : formData.TrainingMonthNum;
                formData.TrainingPeriod    = data.TrainingPeriod   ? data.TrainingPeriod              : formData.TrainingPeriod  ;
                formData.TrainingPurpose   = data.TrainingPurpose  ? data.TrainingPurpose             : formData.TrainingPurpose ;

                TrainningEmpName           = data.TrainningEmpName ? data.TrainningEmpName            : TrainningEmpName;
                this.setState({
                    formData,
                    TrainningEmpName,
                    DateRangeForm: [{
                        startDate: FromDate ? new Date(FromDate[2], FromDate[1] - 1, FromDate[0]) : DateRangeForm.startDate,
                        endDate: ToDate ? new Date(ToDate[2], ToDate[1] - 1, ToDate[0]) : DateRangeForm.endDate,
                        key: 'selection'
                    }]
                }, () => {
                    if (data.ProCurrencyID) {
                        this.onChange('CurrencyID', {value: data.ProCurrencyID})
                    }
                    if (data.TrainingPurpose) {
                        this.TrainingPurposeRef.current.value = data.TrainingPurpose;
                    }
                    if (data.Content) {
                        this.ContentRef.current.value = data.Content;
                    }
                })
            }
        });
    }

    onChange = (nameField, e) => {
        if (!e || this.fieldJustChanged.includes(nameField)) {
            this.fieldJustChanged.splice(this.fieldJustChanged.indexOf(e => e === nameField));
            return false;
        }
        if (['ProAverageCosts', 'ProExchangeRate', 'ProCost', 'ProCCost'].includes(nameField)) {
            this.fieldJustChanged.push(nameField);
        }
        if (!this.state.isUpdated) {
            this.setState({isUpdated: true})
        }
        let value = e.target?.value || e.value;

        setTimeout(() => {
            const { formData } = this.state;
            switch (nameField) {
                case "CurrencyID": {
                    const { getLoadCurrency } = this.props;
                    formData.ProExchangeRate = getLoadCurrency.find(e => e.CurrencyID === value).ExchangeRate;
                    this.fieldJustChanged.push('ProExchangeRate');
                    if (formData.ProCost) {
                        formData.ProCCost = formData.ProCost * formData.ProExchangeRate;
                    }
                    break;
                }
                case "ProNumber": {
                    if (value < 0) {
                        value = 0;
                    }
                    if (formData.ProAverageCosts !== undefined) {
                        formData.ProCost = (value * formData.ProAverageCosts).toFixed(2);
                        this.fieldJustChanged.push('ProCost');
                    }
                    if (formData.ProExchangeRate !== undefined && formData.ProCost !== undefined) {
                        formData.ProCCost = formData.ProCost * formData.ProExchangeRate;
                    }
                    break;
                }
                case "ProAverageCosts": {
                    this.fieldJustChanged.push('ProAverageCosts');
                    if (value < 0) {
                        value = 0;
                    }
                    value = (+value).toFixed(2);
                    if (formData.ProNumber !== undefined) {
                        formData.ProCost = (formData.ProNumber * value).toFixed(2);
                        this.fieldJustChanged.push('ProCost');
                    }
                    if (formData.ProExchangeRate !== undefined && formData.ProCost !== undefined) {
                        formData.ProCCost = formData.ProCost * formData.ProExchangeRate;
                        this.fieldJustChanged.push('ProCCost');
                    }
                    break;
                } 
                case "ProExchangeRate": {
                    this.fieldJustChanged.push('ProExchangeRate');
                    if (value < 0) {
                        value = 0;
                    }
                    if (formData.ProCost !== undefined) {
                        formData.ProCCost = formData.ProCost * value;
                    }
                    break;
                } 
                case 'TrainingMonthNum': {
                    if (value < 0) {
                        value = 0;
                    }
                    break;
                }
                case 'TrainingPeriod': {
                    if (value < 0) {
                        value = 0;
                    }
                    break;
                }
                case 'ProCost': {
                    this.fieldJustChanged.push('ProCost');
                    if (value < 0) {
                        value = 0;
                    }
                    value = (+value).toFixed(2);
                    break;
                }
                default: {
                    break;
                }
            }

            formData[nameField] = value;
            this.setState({
                formData: formData
            }, () => {
                setTimeout(() => {
                    this.fieldJustChanged = this.fieldJustChanged.filter(e => e !== nameField);
                }, 0)
                if (nameField === 'TrainingFieldID') {
                    this.TrainingCourse.state.value = '';
                    if (value) {
                        return this.loadTrainingCourse(true);
                    }
                    this.setState({
                        dataTrainingCourse: {
                            rows: [],
                            total: 0
                        }
                    });
                } else if (nameField === 'TrainingCourseID') {
                    if (value) {
                        return this.loadTrainingCourseDetail(value.TrainingCourseID);
                    }
                }
            });
        }, 0);
    };

    onChangeDate =(ranges) => {
        if (ranges) {
            this.setState({ DateRangeForm: ranges})
        }
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});

    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (!_.isEmpty(dataOldAttachments)) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = e => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map(d => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
        if (!this.state.isUpdated) {
            this.setState({ isUpdated: true });
        }
    };

    loadAttachments = () => {
        const { ProposalID } = this.getInfo();
        const param = {
            KeyID: ProposalID ? ProposalID : '',
            TableName: 'D38T2000'
        };
        if (_.isEmpty(param.KeyID)) {
            Config.popup.show("INFO", Config.lang("DHR_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem"));
            return false;
        }
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
        });
    };

    loadForm = () => {
        const { ProBatchID, ProposalID } = this.getInfo();
        const params = {
            DivisionID: Config.getDivisionID(),
            ProBatchID: ProBatchID,
            ProposalID: ProposalID,
            Language: Config.language || "84"
        };

        return new Promise((response, reject) => {
            this.props.W38F2001Actions.loadForm(params, (errors, data) => {
                if (errors) {
                    let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    reject(false);
                }
                if(data) {
                    const FromDate = data.FromDate ? data.FromDate.split('/') : null;
                    const ToDate   = data.ToDate   ? data.ToDate.split('/')   : null;
                    this.setState({
                        formData: {
                            //...this.state.formData,
                            TrainingFieldID: { TrainingFieldID: data.TrainingFieldID ? data.TrainingFieldID : "", TrainingFieldName: data.TrainingFieldName ? data.TrainingFieldName : "" },
                            TrainingCourseID: { TrainingCourseID: data.TrainingCourseID ? data.TrainingCourseID : "", TrainingCourseName: data.TrainingCourseName ? data.TrainingCourseName : "" },
                            IsInternal: data.IsInternal ? data.IsInternal : 0,
                            ProNumber: data.ProNumber ? data.ProNumber : 0,
                            ProjectID: { ProjectID: data.ProjectID ? data.ProjectID : "", ProjectName: data.ProjectName ? data.ProjectName : ''},
                            DepartmentID: data.DepartmentID ? data.DepartmentID : "",
                            TeamID: data.TeamID ? data.TeamID : "",
                            TrainingPurpose: data.TrainingPurpose ? data.TrainingPurpose : "",
                            Content: data.Content ? data.Content : "",
                            TrainingPeriod: data.TrainingPeriod ? data.TrainingPeriod : 0,
                            TrainingMonthNum: data.TrainingMonthNum ? data.TrainingMonthNum : 0,
                            ProAverageCosts: data.ProAverageCosts ? data.ProAverageCosts : 0,
                            CurrencyID: data.ProCurrencyID ? data.ProCurrencyID : "",
                            ProCost: data.ProCost ? data.ProCost : 0,
                            ProExchangeRate: data.ProExchangeRate ? data.ProExchangeRate : 0,
                            ProNote: data.ProNote ? data.ProNote : "",
                            ProCCost: data.ProCCost ? data.ProCCost : 0
                        },
                        TrainningEmpName: data.TrainningEmpName ? data.TrainningEmpName : "",
                        DateRangeForm: [{
                            startDate: FromDate ? new Date(FromDate[2], FromDate[1] - 1, FromDate[0]) : null,
                            endDate: ToDate ? new Date(ToDate[2], ToDate[1] - 1, ToDate[0]) : null,
                            key: 'selection'
                        }]
                    }, () => {
                        const { TrainingFieldID } = data;
                        if (TrainingFieldID) {
                            this.loadTrainingCourse(true, TrainingFieldID, true);
                        }
                        response(true);
                    });
                }
            });
        });
    };

    loadGrid = (Mode = 0) => {
        const { ProBatchID, ProposalID } = this.getInfo();
        const { formData } = this.state;
        const { TrainingCourseID } = formData;
        const params = {
            DivisionID: Config.getDivisionID(),
            Mode: Mode,
            ProBatchID: ProBatchID,
            ProposalID: ProposalID,
            TrainingCourseID: TrainingCourseID?.TrainingCourseID
        };

        this.setState({ gridLoading: true });
        this.props.W38F2001Actions.loadGrid(params, (errors, data) => {
            this.setState({ gridLoading: false });
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if(data) {
                this.setState({
                    selectedEmployeeID: data.map(e => e.EmployeeID)
                });
            }
        })
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    onSave = () => {
        const { ProBatchID, ProposalID, mode } = this.getInfo();
        const { formData, HREmployeeID, TrainningEmpName, DateRangeForm, selectedEmployeeID } = this.state;
        const  {
            TrainingFieldID,
            TrainingCourseID,
            IsInternal,
            ProNumber,
            ProjectID,
            DepartmentID,
            TeamID,
            TrainingPurpose,
            Content,
            TrainingPeriod,
            TrainingMonthNum,
            ProAverageCosts,
            CurrencyID,
            ProCost,
            ProExchangeRate,
            ProNote,
            ProCCost,
        } = formData;

        const { startDate, endDate } = DateRangeForm[0];

        const validateRules = [
            {
                name: "DepartmentID",
                rules: "isRequired",
                value: DepartmentID
            },
            {
                name: "TrainingFieldID",
                rules: "isRequired",
                value: TrainingFieldID
            },
            {
                name: "TrainingCourseID",
                rules: "isRequired",
                value: TrainingCourseID
            }
        ];
        const validateFrom = MForm.formValidation(validateRules);
        if (Object.keys(validateFrom).length > 0) {
            this.setStateErrorText(validateFrom);
            return false;
        }
        const arrAttachments = this._getAttachments();
        let params = {
            DivisionID       : Config.getDivisionID(),
            TranMonth        : Config.getHRTransMonth() || '',
            TranYear         : Config.getHRTransYear() || '',
            TrainingFieldID  : TrainingFieldID  ? TrainingFieldID.TrainingFieldID        : "",
            TrainingCourseID : TrainingCourseID ? TrainingCourseID.TrainingCourseID      : "",
            TrainningEmpName : TrainningEmpName ? TrainningEmpName                       : "",
            FromDate         : startDate        ? moment(startDate).format('YYYY-MM-DD') : '',
            ToDate           : endDate          ? moment(endDate).format('YYYY-MM-DD')   : '',
            IsInternal       : IsInternal       ? 1                                      : 0,
            ProNumber        : ProNumber        ? +ProNumber                             : 0,
            ProjectID        : ProjectID        ? ProjectID.ProjectID                    : "",
            DepartmentID     : DepartmentID,
            TeamID           : TeamID,
            TrainingPurpose  : TrainingPurpose,
            Content          : Content,
            TrainingPeriod   : TrainingPeriod,
            TrainingMonthNum : TrainingMonthNum,
            ProAverageCosts  : ProAverageCosts,
            ProCurrencyID    : CurrencyID,
            ProCost          : ProCost ? +ProCost : 0,
            ProExchangeRate  : ProExchangeRate ? +ProExchangeRate : 0,
            ProNote          : ProNote,
            ProCCost         : ProCCost ? +ProCCost : 0,
            Language         : Config.language || '84',
            arrAttachment    : JSON.stringify(arrAttachments),
            employee         : JSON.stringify(selectedEmployeeID)
        };

        if (mode === 'add') {
            params = { ...params, HREmployeeID };
        }
        else if (mode === "edit") params = { ...params, ProBatchID, ProposalID };
        const notifyBeforeSave = selectedEmployeeID.length === params.ProNumber ? 
                                Config.lang("DHR_Ban_co_chac_muon_luu_khong?") :
                                Config.lang("DHR_So_luong_nhan_vien_khong_bang_de_xuat")
        Config.popup.show('YES_NO', notifyBeforeSave, () => {
            this.setState({ formLoading: true });
            this.props.W38F2001Actions[mode](params, (errors, data) => {
                this.setState({ formLoading: false });
                if (errors) {
                    let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if (data.Status === 1) {
                    const msg = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', msg);
                    return false;
                } else {
                    Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                    setTimeout(() => {
                        this.onBack();
                    }, 2100);
                }
            });
        });
    };

    getDefaultDepartment = () => {
        const user = Config.profile;
        if (user) {
            this.setState({HREmployeeID: user.HREmployeeID});
            const u = Config.getUser({ EmployeeID: user.HREmployeeID });
            if (u && u.DepartmentID) {
                const { formData } = this.state;
                formData.DepartmentID = u.DepartmentID
                this.props.W38F2001Actions.resetProps(u.DepartmentID);
                this.setState({formData});
            }
        }
    }

    getInfo = (flag, nextProps) => {
        const { location } = nextProps ? nextProps : this.props;
        if (location && location.state) {
            return {
                mode: location.state.mode ? location.state.mode : 'add',
                ProposalID: location.state.ProposalID ? location.state.ProposalID  : '',
                ProBatchID: location.state.ProBatchID ? location.state.ProBatchID  : '',
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W38F2000");
                return null;
            }
            return false;
        }
    };

    UNSAFE_componentWillMount = async () => {
        const { mode } = this.getInfo();
        if (mode === 'add') {
            this.getDefaultDepartment();
        } else {
            await this.loadForm();
            this.loadGrid(1);
            this.loadAttachments();
        }
    }

    componentDidMount = () => {
        if (!this.getInfo()) return;
        const { mode } = this.getInfo();
        this.loadCboDepartments();
        this.loadCboTeams();
        this.loadCboProjects();
        this.loadCurrency();
        this.loadCboTrainingField();
        if(mode && mode !== "add") {
            if (mode === 'view') {
                this.setState({disabled: true});
            } else {
                this.setState({isUpdated: true});
            }
        }
        this.onChange('ProAverageCosts', {value: 0});
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // const {TransID} = this.getInfo(false, nextProps);
        // const {formData} = this.state;
        // if (TransID !== formData.TransID) {
        //     this.loadForm();
        //     this.loadAttachments();
        // }
    };

    openEmployeeSelect = () => {
        const { selectedEmployeeID, NotJoinTrain } = this.state;
        this.setState({
            showEmployeeSelect: true,
            dataDefaults: {
                selectedRowKeys: selectedEmployeeID,
                NotJoinTrain: NotJoinTrain,
                isLoaded: true
            }
        });
    };

    closeEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: false, dataDefaults: null });
    };

    onSelectedEmployee = (EmployeeIDs) => {
        const { selectedEmployeeID } = this.state;
        const trainingDataEmployeeID = selectedEmployeeID.map(e => e.EmployeeID);
        this.closeEmployeeSelect();
        EmployeeIDs.forEach(id => {
            if (trainingDataEmployeeID.indexOf(id) < 0)  {
                this.setState({ gridLoading: true });
                this.loadGrid(1);
                if (!this.state.isUpdated) {
                    this.setState({ isUpdated: true });
                }
                return;
            }
        })
    };

    onDelete = ({EmployeeID}) => {
        Config.popup.show('YES_NO', Config.lang("DHR_Ban_co_chac_muon_xoa?"), () => {
            this.props.W38F2001Actions.removeItem(EmployeeID, error => {
                if (error) {
                    Config.popup.show('INFO', Config.lang("DHR_Loi_chua_xac_dinh"));
                }
                // Remove EmployeeID out EmployeeIDs state
                const { selectedEmployeeID } = this.state;
                this.setState({
                    selectedEmployeeID: selectedEmployeeID.filter(e => e !== EmployeeID)
                });
            });
        });
    };

    onBack = () => {
        this.props.W38F2001Actions.resetProps();
        browserHistory.goBack();
    };

    renderEmpProfile = e => {
        const { classes } = this.props;
        const { data } = e.row;

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}>{data.EmployeeName} - {data.EmployeeID}</div>
                    <div className={classes.divText}>{data.DutyName || ""}</div>
                </div>
            </div>
        );
    };

    renderAction = e => {
        const { mode } = this.getInfo();
        const { data } =  e.row;
        return (
            <GridActionBar>
                <div className="">
                <IconButton
                    disabled={ mode === 'view'}
                    aria-label="delete"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => this.onDelete(data)}
                >
                    <Icons name={"delete"}/>
                </IconButton>
                </div>
            </GridActionBar>
        );
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { mode } = this.getInfo();
        const { getCboDepartments, getCboTeam, getLoadCurrency, dataForm, dataGrid, classes } = this.props;

        const {dataCboTrainingField, dataTrainingCourse, dataCboProjects, loadingTrainingField, loadingTrainingCourse, loadingProjects, gridLoading, expandedFirst, error,
            dataOldAttachments, formLoading, isUpdated, disabled, showEmployeeSelect, showEmployeeList, formData, TrainningEmpName, DateRangeForm, dataDefaults
        } = this.state;

        const { TrainingCourseID, IsInternal, ProNumber, TrainingPeriod, TrainingMonthNum, ProAverageCosts, ProCost, ProExchangeRate, ProCCost } = formData;
        return (
            <>
                {showEmployeeSelect && <W09F6000 open={showEmployeeSelect} dataDefaults={dataDefaults} FormID={"W38F2000"}
                    TrainingCourseID={TrainingCourseID?.TrainingCourseID}
                    onCheckedNotJoinTrain={value => this.setState({NotJoinTrain: value})}
                    onChosen={this.onSelectedEmployee} 
                    onClose={this.closeEmployeeSelect} />}
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Lap_de_xuat_dao_tao")}
                        onBack={this.onBack}
                    >
                        <ButtonCustom name={Config.lang('DHR_Chon_nhan_vien')}
                            color={"primary"}
                            variant={"custom"}
                            icon={<InlineSVG className={'btn-icon-svg'} src={require('../../../../assets/images/general/people.svg')} />}
                            style={{ textTransform: 'uppercase' }}
                            size={"large"}
                            className={"mgr5"}
                            disabled={formLoading || mode === 'view' || !(TrainingCourseID && TrainingCourseID.TrainingCourseID)}
                            onClick={this.openEmployeeSelect} />
                        <ButtonGeneral name={Config.lang('DHR_Luu')}
                            typeButton={'save'}
                            disabled={formLoading || !isUpdated || mode === 'view'}
                            loading={formLoading}
                            style={{ textTransform: 'uppercase', marginLeft: 'auto' }}
                            size={"large"}
                            onClick={this.onSave} />
                    </ActionToolbar>
                </FormGroup>
                <div className={"pdt10 pdb10 display_row align-center align-between flex-wrap"}>

                    <Typography style={{fontSize: "15px", fontWeight:"bold"}}>
                        {Config.lang("DHR_Thong_tin_chung")}
                    </Typography>

                    <Col xs={4}>
                        <ButtonCustom name={Config.lang('DHR_Danh_sach_nhan_vien_tham_gia') + ` (${dataGrid.length})`}
                                typebutton={'save'}
                                color={"primary"}
                                variant={"custom"}
                                icon={showEmployeeList ? <ChevronRightIcon fontSize={"large"} /> : <ChevronLeftIcon fontSize={"large"} />}
                                loading={formLoading}
                                style={{ textTransform: 'uppercase' }}
                                size={"large"}
                                onClick={() => this.setState({showEmployeeList: !showEmployeeList})} />
                        
                    </Col>
                </div>
                <Col xs={12} sm={12} md={12} lg={12} style={{height: 'auto'}}>
                    <Row style={{height: '100%'}}>
                        <Col xs={showEmployeeList ? 8 : 12} sm={showEmployeeList ? 8 : 12} md={showEmployeeList ? 8 : 12} lg={showEmployeeList ? 8 : 12} className={classes.panel}>
                        <div>
                            <Row>
                                <Col xs={12} sm={showEmployeeList ? 6: 4} md={showEmployeeList ? 6: 4} lg={showEmployeeList ? 6: 4}>
                                    <Combo.LoadMore
                                        error={error && error['TrainingFieldID']}
                                        dataSource={dataCboTrainingField}
                                        skip={this.filterCboTrainingField.skip}
                                        limit={this.filterCboTrainingField.limit}
                                        displayExpr={'TrainingFieldName'}
                                        keyExpr={'TrainingFieldID'}
                                        valueExpr={'TrainingFieldID'}
                                        value={dataForm && dataForm.TrainingFieldID ? {TrainingFieldID: dataForm.TrainingFieldID, TrainingFieldName: dataForm.TrainingFieldName} : ''}
                                        shrink={true}
                                        disabled={disabled}
                                        required
                                        showClearButton={true}
                                        margin={"normal"}
                                        loading={loadingTrainingField}
                                        stylingMode={'underlined'}
                                        label={Config.lang('DHR_Linh_vuc_dao_tao')}
                                        // showCheckBoxMode={"none"}
                                        onValueChanged={(e) => this.onChange("TrainingFieldID", e)}
                                        onInputChanged={(e) => {
                                            if (this.filterCboTrainingField.timer) clearTimeout(this.filterCboTrainingField.timer);
                                            this.filterCboTrainingField.strSearch = e.target.value;
                                            this.filterCboTrainingField.timer = setTimeout(() => {
                                                this.filterCboTrainingField.skip = 0;
                                                this.filterCboTrainingField.limit = 10;
                                                this.loadCboTrainingField(true);
                                            }, 700);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboTrainingField.skip = e.skip;
                                            this.filterCboTrainingField.limit = e.limit;
                                            this.loadCboTrainingField();
                                        }}
                                    />
                                </Col>
                                <Col xs={12} sm={showEmployeeList ? 6: 4} md={showEmployeeList ? 6: 4} lg={showEmployeeList ? 6: 4}>
                                    <Combo.LoadMore
                                        error={error && error['TrainingCourseID']}
                                        ref={ref => this.TrainingCourse = ref}
                                        dataSource={dataTrainingCourse}
                                        skip={this.filterTrainingCourse.skip}
                                        limit={this.filterTrainingCourse.limit}
                                        displayExpr={'TrainingCourseName'}
                                        keyExpr={'TrainingCourseID'}
                                        valueExpr={'TrainingCourseID'}
                                        value={dataForm && dataForm.TrainingCourseID ? { TrainingCourseID: dataForm.TrainingCourseID, TrainingCourseName: dataForm.TrainingCourseName} : ''}
                                        shrink={true}
                                        disabled={disabled}
                                        required
                                        showClearButton={true}
                                        margin={"normal"}
                                        loading={loadingTrainingCourse}
                                        stylingMode={'underlined'}
                                        label={Config.lang('DHR_Khoa_dao_tao')}
                                        // showCheckBoxMode={"none"}
                                        onValueChanged={(e) => this.onChange("TrainingCourseID", e)}
                                        onInputChanged={(e) => {
                                            if (this.filterTrainingCourse.timer) clearTimeout(this.filterTrainingCourse.timer);
                                            this.filterTrainingCourse.strSearch = e.target.value;
                                            this.filterTrainingCourse.timer = setTimeout(() => {
                                                this.filterTrainingCourse.skip = 0;
                                                this.filterTrainingCourse.limit = 10;
                                                this.loadTrainingCourse(true);
                                            }, 700);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterTrainingCourse.skip = e.skip;
                                            this.filterTrainingCourse.limit = e.limit;
                                            this.loadTrainingCourse();
                                        }}
                                    />
                                </Col>
                                {!showEmployeeList && <Col xs={12} sm={12} md={4} lg={4}>
                                    <FormControl variant={"standard"}
                                                margin={"normal"}
                                                fullWidth={true}>
                                        <InputLabel
                                            shrink={true}
                                        >
                                            {Config.lang("DHR_Nguoi_dao_tao")}
                                        </InputLabel>
                                        <Row>
                                            <Col xs={9} sm={9} md={8} lg={9}>
                                                <TextField
                                                    variant={"standard"}
                                                    value={ TrainningEmpName || ''}
                                                    //required={true}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    disabled={disabled}
                                                    onChange={e => this.setState({TrainningEmpName: e.target.value})}
                                                    margin={"normal"}
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col xs={3} sm={3} md={4} lg={3} style={{padding: 0}}>
                                                <Checkbox
                                                    style={{marginTop: 15, marginRight: 0}}
                                                    checked={Boolean(IsInternal) || false}
                                                    disabled={disabled}
                                                    label={Config.lang("DHR_Noi_bo")}
                                                    color={'primary'}
                                                    onChange={(_e, checked) => this.onChange('IsInternal', {value: checked})}
                                                />
                                            </Col>
                                        </Row>
                                    </FormControl>
                                </Col>}
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={showEmployeeList ? 6: 4} lg={showEmployeeList ? 6: 4}>
                                    <FormControl variant={"standard"}
                                                margin={"normal"}
                                                fullWidth={true}>
                                        <InputLabel shrink={true} >
                                            {Config.lang("DHR_Thoi_gian_dao_tao")}
                                        </InputLabel>
                                        <DateRangeBox
                                            //className={classes.dateRanger}
                                            disabled={disabled || mode === 'view'}
                                            showClearIcon={true}
                                            onChange={ranges => {this.onChangeDate(ranges)}}
                                            showDateDisplay={false}
                                            ranges={DateRangeForm}
                                            fullWidth/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} sm={12} md={showEmployeeList ? 6: 4} lg={showEmployeeList ? 6: 4}>
                                    <FormControl variant={"standard"}
                                                margin={"normal"}
                                                fullWidth={true}>
                                        <InputLabel shrink={true} >
                                            {Config.lang("DHR_So_luong_de_xuat")}
                                        </InputLabel>
                                        <TextField
                                            error={error && error['ProNumber']}
                                            variant={"standard"}
                                            value={String( ProNumber >= 0 ? ProNumber : '')}
                                            // required={true}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            type={"number"}
                                            disabled={disabled}
                                            inputProps={{min: 0}}
                                            onChange={(e) => this.onChange('ProNumber', e)}
                                            margin={"normal"}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Col>
                                {showEmployeeList && <Col xs={12} sm={12} md={6} lg={6}>
                                    <FormControl variant={"standard"}
                                                margin={"normal"}
                                                fullWidth={true}>
                                        <InputLabel shrink={true} >
                                            {Config.lang("DHR_Nguoi_dao_tao")}
                                        </InputLabel>
                                        <Row>
                                            <Col xs={8} sm={9} md={8} lg={9}>
                                                <TextField
                                                    error={error && error['TrainningEmpName']}
                                                    variant={"standard"}
                                                    value={ TrainningEmpName || ''}
                                                    //required={true}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    disabled={disabled}
                                                    onChange={e => this.setState({TrainningEmpName: e.target.value})}
                                                    margin={"normal"}
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col xs={4} sm={3} md={4} lg={3}>
                                                <Checkbox
                                                    style={{marginTop: 15}}
                                                    checked={ Boolean(IsInternal) || false}
                                                    disabled={disabled}
                                                    label={Config.lang("DHR_Noi_bo")}
                                                    color={'primary'}
                                                    onChange={(_e, checked) => this.onChange('IsInternal', {value: checked})}
                                                />
                                            </Col>
                                        </Row>
                                    </FormControl>
                                </Col>}
                                <Col xs={12} sm={12} md={showEmployeeList ? 6: 4} lg={showEmployeeList ? 6: 4}>
                                    <Combo.LoadMore
                                        dataSource={dataCboProjects}
                                        skip={this.filterCboProjects.skip}
                                        limit={this.filterCboProjects.limit}
                                        displayExpr={'ProjectName'}
                                        keyExpr={'ProjectID'}
                                        valueExpr={'ProjectID'}
                                        value={dataForm && dataForm.ProjectID ? { ProjectID: dataForm.ProjectID, ProjectName: dataForm.ProjectName } : ''}
                                        shrink={true}
                                        disabled={disabled}
                                        showClearButton={true}
                                        margin={"normal"}
                                        loading={loadingProjects}
                                        stylingMode={'underlined'}
                                        label={Config.lang('DHR_Du_an')}
                                        // showCheckBoxMode={"none"}
                                        onValueChanged={(e) => this.onChange("ProjectID", e)}
                                        onInputChanged={(e) => {
                                            if (this.filterCboProjects.timer) clearTimeout(this.filterCboProjects.timer);
                                            this.filterCboProjects.strSearch = e.target.value;
                                            this.filterCboProjects.timer = setTimeout(() => {
                                                this.filterCboProjects.skip = 0;
                                                this.filterCboProjects.limit = 20;
                                                this.loadCboProjects(true);
                                            }, 700);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboProjects.skip = e.skip;
                                            this.filterCboProjects.limit = e.limit;
                                            this.loadCboProjects();
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <Combo
                                        InputLabelProps={{ shrink: true }}
                                        error={error && error['DepartmentID']}
                                        margin={"normal"}
                                        required={true}
                                        showClearButton={true}
                                        stylingMode={"underlined"}
                                        label={Config.lang("DHR_Phong_ban")}
                                        dataSource={getCboDepartments}
                                        displayExpr={"DepartmentName"}
                                        valueExpr={"DepartmentID"}
                                        onValueChanged={e => this.onChange("DepartmentID", e)}
                                        value={dataForm && dataForm.DepartmentID ? dataForm.DepartmentID : ''}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <Combo
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        dataSource={getCboTeam}
                                        margin={"normal"}
                                        showClearButton={true}
                                        stylingMode={"underlined"}
                                        label={Config.lang("DHR_To_nhom")}
                                        onValueChanged={e => this.onChange("TeamID", e)}
                                        displayExpr={"TeamName"}
                                        valueExpr={"TeamID"}
                                        value={dataForm && dataForm.TeamID ? dataForm.TeamID : ''}
                                        disabled={disabled}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        inputRef={this.TrainingPurposeRef}
                                        label={Config.lang("DHR_Ly_do_dao_tao")}
                                        variant={"standard"}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={disabled}
                                        defaultValue={dataForm && dataForm.TrainingPurpose ? dataForm.TrainingPurpose : ''}
                                        onChange={e => this.onChange("TrainingPurpose", e)}
                                        margin={"normal"}
                                        multiline
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        inputRef={this.ContentRef}
                                        label={Config.lang("DHR_Noi_dung_dao_tao")}
                                        variant={"standard"}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={disabled}
                                        defaultValue={dataForm && dataForm.Content ? dataForm.Content : ''}
                                        onChange={(e) => this.onChange('Content', e)}
                                        margin={"normal"}
                                        multiline
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            {/* collap div employee */}
                            <div>
                                <ExpansionPanel
                                    expanded={expandedFirst}
                                    style={{boxShadow: "none"}}
                                    onChange={() => this.setState({expandedFirst: !expandedFirst})}
                                >
                                    <ExpansionPanelSummary className={"hide"}/>
                                    <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                                        <div style={{ width: "100%" }}>
                                            <Row>
                                                <Col xs={12} sm={2} md={2} lg={2}>
                                                    <TextField
                                                        label={Config.lang("DHR_So_gio_dao_tao")}
                                                        variant={"standard"}
                                                        margin={"normal"}
                                                        value={String( TrainingPeriod ? TrainingPeriod : '')}
                                                        type={'number'}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        disabled={disabled}
                                                        inputProps={{min: 0}}
                                                        fullWidth
                                                        onChange={e => this.onChange("TrainingPeriod", e)}
                                                    />
                                                </Col>

                                                <Col xs={12} sm={2} md={2} lg={2}>
                                                    <TextField
                                                        label={Config.lang("DHR_So_thang_dao_tao")}
                                                        variant={"standard"}
                                                        margin={"normal"}
                                                        value={String( TrainingMonthNum >= 0 ? TrainingMonthNum : '')}
                                                        type={'number'}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        disabled={disabled}
                                                        inputProps={{min: 0}}
                                                        fullWidth
                                                        onChange={e => this.onChange("TrainingMonthNum", e)}
                                                    />
                                                </Col>

                                                <Col xs={12} sm={4} md={4} lg={4}>
                                                    <FormControl variant={"standard"}
                                                                margin={"normal"}
                                                                fullWidth={true}>
                                                        <InputLabel
                                                            shrink={true}
                                                        >
                                                            {Config.lang("DHR_Chi_phi_binh_quan")}
                                                        </InputLabel>
                                                        <Row>
                                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                                <TextField
                                                                    error={error && error["ProAverageCosts"]}
                                                                    variant={"standard"}
                                                                    margin={"normal"}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    disabled={disabled}
                                                                    className={classes.textField}
                                                                    InputProps={{
                                                                        min: 0,
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    value={String( ProAverageCosts >= 0 ? ProAverageCosts : '')}
                                                                    fullWidth
                                                                    onChange={e => this.onChange("ProAverageCosts", e)}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                                <Combo
                                                                    margin={"normal"}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    stylingMode={"underlined"}
                                                                    placeholder={"VND"}
                                                                    dataSource={getLoadCurrency}
                                                                    displayExpr={"CurrencyName"}
                                                                    valueExpr={"CurrencyID"}
                                                                    onValueChanged={e => this.onChange("CurrencyID", e)}
                                                                    value={dataForm && dataForm.ProCurrencyID ? dataForm.ProCurrencyID : ''}
                                                                    disabled={disabled}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </FormControl>
                                                </Col>
                                                <Col xs={12} sm={2} md={2} lg={2}>
                                                    <TextField
                                                        error={error && error["ProCost"]}
                                                        variant={"standard"}
                                                        margin={"normal"}
                                                        label={Config.lang("DHR_Tong_chi_phi")}
                                                        className={classes.textField}
                                                        disabled={disabled}
                                                        InputLabelProps={{shrink: true}}
                                                        InputProps={{
                                                            min: 0,
                                                            inputComponent: NumberFormatCustom
                                                        }}
                                                        fullWidth
                                                        value={String( ProCost >= 0 ? ProCost : '')}
                                                        onChange={e => this.onChange("ProCost", e)}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={2} md={2} lg={2}>
                                                    <TextField
                                                        variant={"standard"}
                                                        margin={"normal"}
                                                        label={Config.lang("DHR_Ty_gia")}
                                                        className={classes.textField}
                                                        disabled={disabled}
                                                        InputLabelProps={{shrink: true}}
                                                        InputProps={{
                                                            min: 0,
                                                            inputComponent: NumberFormatCustom
                                                        }}
                                                        onChange={e => this.onChange("ProExchangeRate", e)}
                                                        value={String( ProExchangeRate ? (+ProExchangeRate).toFixed(2) : '')}
                                                        fullWidth
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={10} md={10} lg={10}>
                                                    <TextField
                                                        label={Config.lang("DHR_Ghi_chu")}
                                                        variant={"standard"}
                                                        InputLabelProps={{ shrink: true }}
                                                        disabled={disabled}
                                                        defaultValue={dataForm && dataForm.ProNote ? dataForm.ProNote : ''}
                                                        onChange={(e) => this.onChange('ProNote', e)}
                                                        margin={"normal"}
                                                        multiline
                                                        fullWidth
                                                    />
                                                </Col>
                                                <Col xs={12} sm={2} md={2} lg={2}>
                                                    <TextField
                                                        // error={error && error["ProCCost"]}
                                                        variant={"standard"}
                                                        margin={"normal"}
                                                        label={Config.lang("DHR_Tong_chi_phi_quy_doi")}
                                                        className={classes.textField}
                                                        disabled={true}
                                                        InputLabelProps={{shrink: true}}
                                                        InputProps={{
                                                            min: 0,
                                                            inputComponent: NumberFormatCustom
                                                        }}
                                                        fullWidth
                                                        value={String( ProCCost >= 0 ? (+ProCCost).toFixed(2) : '')}
                                                        onChange={e => this.onChange("ProCCost", e)}
                                                    />
                                                    <span style={{position: 'absolute', bottom: 5, right: 15}}>VNĐ</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <div style={{cursor: "pointer", marginTop: 10, marginBottom: 20}} className={"display_col align-center valign-middle"}  onClick={() => this.setState({expandedFirst: !expandedFirst})}>
                                    <i className={"fas display_row align-center " + (expandedFirst ? "fa-angle-double-up" : "fa-angle-double-down")} style={{fontSize: 20}}/>
                                    <div className={classes.divText}>
                                        <Typography> {expandedFirst ? Config.lang("DHR_Thu_nho") :  Config.lang("DHR_Mo_rong")}</Typography>
                                    </div>
                                </div>
                            </div>

                            <FormGroup>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} style={{zIndex: 1}}>
                                    <Attachments
                                        ref={ref => (this.attRef = ref)}
                                        showButton={true}
                                        files={dataOldAttachments}
                                        disabled={formLoading || disabled}
                                        maxLength={5}
                                        uploading={formLoading}
                                        onUploading={this.onUploading}
                                        onChanged={this.onChangeAttachments}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        </div>
                        </Col>
                        <Col style={{borderLeft: '1px solid rgba(0, 0, 0, 0.42)', maxHeight: expandedFirst ? 825 : 672}} 
                            className={classes.panel + ' ' + (showEmployeeList ? ('col-xs-4 col-md-4 col-sm-4 col-lg-4 ' + classes.open) : classes.close)}
                        >
                            <GridContainer
                                loading={showEmployeeList ? gridLoading : false}
                                // selectedRowKey={selectedRowKeys}
                                style={{ border: "none" }}
                                dataSource={dataGrid && dataGrid}
                                showBorders={false}
                                showColumnHeaders={false}
                                noDataText={Config.lang("DHR_No_data")}
                                typePaging={"remote"}
                                selection={{ mode: "single" }}
                                height={'100%'}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                            >
                                <Column cellRender={this.renderEmpProfile} />
                                <Column
                                    alignment={"right"}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    visible={!Config.isMobile}
                                    cellRender={this.renderAction}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </Col>

                <ButtonGeneral name={Config.lang('DHR_Luu')}
                    typeButton={'save'}
                    disabled={formLoading || !isUpdated || (mode === 'view')}
                    loading={formLoading}
                    style={{ textTransform: 'uppercase',float: 'right' }}
                    size={"large"}
                    onClick={this.onSave} />
            </>
        )
    }
}

class NumberFormatCustom extends Component {

    render() {
        const {name, inputRef, onChange, ...props} = this.props;
        return (
            <NumberFormat
                {...props}
                // value={value}
                getInputRef={inputRef}
                thousandSeparator={true}
                onValueChange={(e) => {
                    if (onChange) {
                        onChange({
                            target: {
                                name: name,
                                value: e.value
                            }
                        })
                    }
                }}
            />
        );
    }
}

export default compose(
    connect(
        state => ({
            cboTrainingField  : state.W38F2001.cboTrainingField,
            cboTrainingCourse : state.W38F2001.cboTrainingCourse,
            dataForm          : state.W38F2001.dataForm,
            dataGrid          : state.W38F2001.dataGrid,
            getLoadCurrency   : state.general.getLoadCurrency,
            getCboDepartments : state.general.getCboDepartments,
            getCboTeam        : state.general.getCboTeam,
            getLoadEducation  : state.general.getLoadEducation,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W38F2001Actions: bindActionCreators(W38F2001Actions, dispatch)
        })
    ),
    withStyles(styles, {withTheme: true})
)(W38F2001);