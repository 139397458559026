/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 7/3/2020
 */

import React, { Component } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { IconButton, withStyles, Tooltip, Icon } from "@material-ui/core";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W39F1010Actions from "../../../../redux/W3X/W39F1010/W39F1010_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Modal from "../../../common/modal/modal";
import { FormGroup, Row, Col, Image } from "react-bootstrap";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import Filter from "../../../filter/filter";
import Config from "../../../../config";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import moment from "moment";
import _ from "lodash";
import ButtonCustom from "../../../common/button/button-custom";
import { Combo } from "../../../common/form-material";
import Rating from "@material-ui/lab/Rating";

import W39F1012 from "../W39F1012/W39F1012";
import Status from "../../../common/status/status";

const styles = (theme) => ({
    headerTitle: {
        "& > :last-child": {
            justifyContent: "flex-end !important",
        },
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000",
    },
    dateInfo: {
        display: "flex",
        alignItems: "center",
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757",
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
        color: theme.palette.grey["600"],
        "&.statusColor": {
            color: theme.palette.success.main,
        },
    },

    evaluateAction: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    iconEmpty: {
        color: theme.palette.warning.main,
    },
    iconChecked: {
        "& .MuiRating-icon.MuiRating-iconEmpty": {
            color: theme.palette.info.main,
        },
    },
});

class W39F1010 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridLoading: false,
            cBoLoading: false,
            employeeLoading: false,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            dataCboStatus: [
                {
                    StatusID: "PROCESS",
                    StatusName: Config.lang("DHR_Dang_thuc_hien"),
                },
                {
                    StatusID: "COMPLETE",
                    StatusName: Config.lang("DHR_Hoan_thanh"),
                },
            ],
            rowData: null,
            isMineVoucher: false,
            showW39F1012: false,
        };
        this.filter = {
            TransTypeID: "",
            StatusID: "",
            employee: null,
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            search: "",
            skip: 0,
            limit: 50,
        };
        this.defaultParams = {
            FormID: "W39F1010",
            Language: Config.language || "84",
        };
    }

    setMineVoucher = () => {
        const { isMineVoucher } = this.state;
        this.setState({ isMineVoucher: !isMineVoucher }, this.loadFormGrid);
    };

    handleResponse = (err, data = []) => {
        if (err) Config.popup.show("INFO", _.get(err, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
        if (data === "isGrid") {
            this.setState({
                gridLoading: false,
            });
        } else {
            this.setState({ cBoLoading: false });
        }
    };

    loadFormGrid = (voucher_id) => {
        const { isMineVoucher } = this.state;
        const params = {
            ...this.defaultParams,
            ..._.omit(this.filter, "employee"),
            EmployeeID: _.get(this.filter.employee, "EmployeeID", ""),
            DivisionID: Config.getDivisionID(),
            IsMineVoucher: Number(isMineVoucher),
            TransID: voucher_id || ""
        };
        this.setState({ gridLoading: true });
        this.props.w39f1010Actions.getFormGrid(params, (err) => this.handleResponse(err, "isGrid"));
    };

    loadCboTransType = () => {
        this.setState({ cBoLoading: true });
        this.props.w39f1010Actions.getTransType(this.handleResponse);
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            ...this.defaultParams,
            ..._.omit(this.filterCboEmployees, ["timer","strSearch"]),
            search: this.filterCboEmployees.strSearch,

        };
        this.setState({ cBoLoading: true });
        this.props.generalActions.getCboEmployees(param, (err, data) => {
            this.handleResponse(err);
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", data.length);
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadFormGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadFormGrid();
    };

    onCloseModal = () => {
        this.loadFormGrid();
        this.setState({
            showW39F1012: false,
        });
    };

    renderEmpProfile = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        const objRenderDate = {
            content: moment.utc(data.CreateDate).format("LT, DD/MM/YYYY"),
            isVisible: Boolean(data.CreateDate),
        };
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} />
                <div className={"w75f2000_info"}>
                    <UserName data={data} />
                    {objRenderDate.isVisible && (
                        <div className={classes.dateInfo}>
                            <Image src={require("../../../../assets/images/icon-calendar.svg")} />
                            <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                                {objRenderDate.content}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderInfo = (e) => {
        const { data } = e.row;
        const { classes } = this.props;
        const arrRenderInfo = [
            {
                content: `${Config.lang("DHR_Thoi_gian")}: ${moment.utc(data.AppraisalDate).format("DD/MM/YYYY")}`,
                isVisible: _.get(data, "AppraisalDate", false),
            },
            {
                content: `${Config.lang("DHR_Loai_danh_gia")}: ${data.TransTypeName}`,
                isVisible: _.get(data, "TransTypeName", false),
            },
            {
                content: `${Config.lang("DHR_Ghi_chu")}: ${data.Note}`,
                isVisible: _.get(data, "Note", false),
            },
        ].filter((item) => Boolean(item.isVisible));
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {arrRenderInfo.map(({ content }, i) => {
                    const preWarpNote = i === arrRenderInfo.length - 1 ? { style: { whiteSpace: "pre-wrap" } } : {};
                    return (
                        <div key={i} {...preWarpNote}>
                            {content}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderStatus = (e) => {
        const { data } = e.row;
        const isCompleted = _.get(data, "StatusID", "PROCESS") === "COMPLETE";
        const status = {
            AppStatusID: isCompleted ? 2 : 0,
            AppStatusName: isCompleted ? Config.lang("DHR_Hoan_thanh") : Config.lang("DHR_Dang_thuc_hien"),
        };
        return (
            <Status data={status} />
        );
    };

    renderActions = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        const isApproved = Number(_.get(data, "IsApproved", 0));
        const isMultiLevel = Number(_.get(data, "IsMultiLevel", 1));
        const evaluatedStatus = _.get(data, "EvaluatedStatus", 0);
        const approveStatus = _.get(data, "ApproveStatus", 0);
        let iconApproval = (
            <Rating
                max={1}
                value={0}
                readOnly
                size={"large"}
                classes={{ root: approveStatus ? classes.iconChecked : "" }}
                emptyIcon={<Icon className="fas fa-check" />}
                icon={<Icon className="fas fa-check" />}
            />
        );
        if (!Boolean(isApproved)) {
            iconApproval = !Boolean(isMultiLevel) ? (
                <IconButton size="small" onClick={() => this.setState({ rowData: data, showW39F1012: true })}>
                    <BorderColorIcon />
                </IconButton>
            ) : (
                <IconButton size="small" style={{ visibility: "hidden" }}>
                    <BorderColorIcon visible="hidden" fontSize="small" />
                </IconButton>
            );
        }
        return (
            <div className={"display_row align-center valign-middle"}>
                <IconButton className={"mgr10"} size="small" onClick={() => this.redirectScreen(1, e)}>
                    <Rating
                        max={1}
                        value={evaluatedStatus / 2}
                        readOnly
                        size={"large"}
                        precision={0.5}
                        classes={{ iconEmpty: classes.iconEmpty }}
                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    />
                </IconButton>
                {iconApproval}
            </div>
        );
    };

    onLoadDataFilter = () => {
        this.loadCboTransType();
        this.loadCboEmployees();
    };

    handleFilterChange = (key, data) => {
        const value = _.get(data, "value", "");
        this.filter = {
            ...this.filter,
            [key]: value,
        };
    };

    redirectScreen = (mode = 0, e = "") => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1011",
            state: {
                Mode: mode,
                FormID: this.defaultParams.FormID,
                EmployeeID: _.get(e, "data.EmployeeID", "03991"),
                TransID: _.get(e, "data.TransID", "39EVCP700000004_03991"),
                EvaluationVoucherID: _.get(e, "data.EvaluationVoucherID", "39EVCP700000004"),
                ApproveLevel: _.get(e, "data.ApproveLevel", 0),
                IsSave: _.get(e, "data.IsSave", 0),
                UserLevel: _.get(e, "data.UserLevel", 1),
            },
        });
    };

    renderFilters = () => {
        const { getTransType } = this.props;
        const { cBoLoading, dataCboEmployees, dataCboStatus } = this.state;
        const { TransTypeID, employee, StatusID } = this.filter;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={getTransType}
                                            displayExpr={"TransTypeName"}
                                            valueExpr={"TransTypeID"}
                                            stylingMode={"outlined"}
                                            value={TransTypeID}
                                            disabled={cBoLoading}
                                            label={Config.lang("DHR_Phieu_danh_gia")}
                                            onValueChanged={(e) => this.handleFilterChange("TransTypeID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={dataCboStatus}
                                            displayExpr={"StatusName"}
                                            valueExpr={"StatusID"}
                                            stylingMode={"outlined"}
                                            value={StatusID}
                                            disabled={cBoLoading}
                                            label={Config.lang("DHR_Trang_thai")}
                                            onValueChanged={(e) => this.handleFilterChange("StatusID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={employee}
                                            loading={cBoLoading}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Nhan_vien")}
                                            showClearButton={true}
                                            onValueChanged={(e) => this.handleFilterChange("employee", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonCustom
                                    name={Config.lang("DHR_Tim_kiem")}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"text"}
                                    onClick={()=>{
                                        this.filter.skip=0;
                                        this.loadFormGrid();
                                    }}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    render() {
        const { classes, getFormGrid } = this.props;
        const { gridLoading, isMineVoucher, showW39F1012, rowData } = this.state;
        return (
            <>
                <Modal
                    open={showW39F1012}
                    maxWidth={"md"}
                    fullWidth={true}
                    title={Config.lang("DHR_Cap_nhat_thong_tin_khac_cho_phieu_danh_gia")}
                    onClose={this.onCloseModal}
                >
                    <W39F1012 data={rowData} onClose={this.onCloseModal} />
                </Modal>
                <div className={"hidden"}>{this.renderFilters()}</div>
                <FormGroup>
                    <ActionToolbar className={classes.headerTitle} title={Config.lang("DHR_Phieu_danh_gia_nhan_vien")}>
                        <Tooltip title={Config.lang("DHR_Phieu_cua_toi")}>
                            <IconButton size="small" onClick={this.setMineVoucher}>
                                <AccountCircleIcon color={isMineVoucher ? "primary" : "inherit"} fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                style={{ border: "none" }}
                                hoverStateEnabled={true}
                                dataSource={_.get(getFormGrid, "rows", [])}
                                totalItems={_.get(getFormGrid, "total", 0)}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid()}
                                typePaging={"remote"}
                                loading={gridLoading}
                                typeShort={window.innerWidth < 768}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                rowAlternationEnabled={false}
                                noDataText={Config.lang("DHR_No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                            >
                                <Column cellRender={this.renderEmpProfile} width={340} />
                                <Column cellRender={this.renderInfo} />
                                <Column cellRender={this.renderStatus} width={160} alignment={"center"} />
                                <Column cellRender={this.renderActions} width={120} />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    }

    componentDidMount = () => {
        const {voucher_id} = Config.getUrlParams(this.props);
        this.loadFormGrid(voucher_id);
    };

    componentDidUpdate = () => {
        const {getFormGrid} = this.props;
        Config.callChildForm({
            FormID: "W39F1010",
            keyExpr: "TransID",
            data: getFormGrid.rows || [],
            onLoad: (params) => this.loadFormGrid(params && params.ID),
            onAction: (e, data) => this.redirectScreen(1, {data: data})
        }, this.props);
    }
}

export default compose(
    connect(
        (state) => ({
            getCboEmployees: state.general.getCboEmployees,
            getTransType: state.W39F1010.getTransType,
            getFormGrid: state.W39F1010.getFormGrid,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w39f1010Actions: bindActionCreators(W39F1010Actions, dispatch),
        }),
    ),
    withStyles(styles, { withTheme: true }),
)(W39F1010);
