/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 06/30/2020
 * @update 06/30/2020
 * @Example
 */

import React from "react";
import { bindActionCreators, compose } from "redux";
import { withStyles, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import ButtonCustom from "../../../common/button/button-custom";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { Image, FormGroup, Row, Col } from "react-bootstrap";
import Status from "../../../common/status/status";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import moment from "moment";
import { browserHistory } from "react-router";
import UserImage from "../../../common/user/user-image";
import NumberFormat from 'react-number-format';

import * as W38F2000Actions from "../../../../redux/W3X/W38F2000/W38F2000_actions";
import UserName from "../../../common/user/user-name";
import Icons from "../../../common/icons/";

const styles = {
    btnFilter: {
        backgroundColor: "#9999993d",
        textTransform: "uppercase"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000"
    }
};

class W38F2000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            FromDate: null,
            ToDate: null,
            gridLoading: false,
            employeeLoading: false,
            disabledFilter: false,
            error: {
                ToProNumber: '',
                ToProCost: ''
            },
            dataCboEmployees: {
                rows: [],
                total: 0
            }
        };
        this.filter = {
            AppStatusID: "",
            EmployeeID: "",
            TrainingCourseID: "",
            FromProNumber: "",
            ToProNumber: "",
            FromProCost: "",
            ToProCost: "",
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 20
        };
        this.filterCboTraining = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 20
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W38F2000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID }
        });
    };

    renderEmpProfile = e => {
        const { classes } = this.props;
        const { data } = e.row;

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>
                    <div className={classes.divText}>{data.DepartmentName || ""}</div>
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                            {data.VoucherDate && Config.convertDate(data.VoucherDate, "", "DD/MM/YYYY, HH:mm:ss", true)}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const { data } = e.row;
        const { classes } = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {data.TrainingCourseName && (
                    <div>
                        {Config.lang("DHR_Khoa_dao_tao")}: {data.TrainingCourseName}
                    </div>
                )}
                {data.DepartmentName && (
                    <div>
                        {Config.lang("DHR_Phong_ban")}: {data.DepartmentName}
                    </div>
                )}
                {data.ProjectName && (
                    <div>
                        {Config.lang("DHR_Du_an")}: {data.ProjectName}
                    </div>
                )}
                {!isNaN(data.ProNumber) && +data.ProNumber >= 0 && (
                    <div>
                        {Config.lang("DHR_So_luong_de_xuat")}: {data.ProNumber}
                    </div>
                )}
                {data.FromDate && data.ToDate && (
                    <div>
                        {Config.lang("DHR_Thoi_gian_dao_tao")}: {moment(data.FromDate).format('DD/MM/YYYY') + ' - ' + moment(data.ToDate).format('DD/MM/YYYY')}
                    </div>
                )}
                {!isNaN(data.ProCost) && +data.ProCost >= 0 && (
                    <div>
                        {Config.lang("DHR_Tong_chi_phi")}: {new Intl.NumberFormat().format(data.ProCost)} {data.ProCurrencyName}
                    </div>
                )}
                {data.ApproverName && (
                    <div>
                        {Config.lang("DHR_Nguoi_duyet")}: {data.ApproverName} {data.DepartmentIDApp ? ' - ' + data.DepartmentIDApp : ''} {data.ApprovedDate ? ' - ' + data.ApprovedDate : ''}
                    </div>
                )}
                {data.AppNote && (
                    <div>
                        {Config.lang("DHR_Ghi_chu_duyet")}: {data.AppNote}
                    </div>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const { data } = e.row;
        return (
            <Status data={data} />
        );
    };

    onAdd = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W38F2001",
            state: { mode: 'add' }
        });
    };

    onView = e => {
        const { data } = e.row;
        browserHistory.push({
            pathname: Config.getRootPath() + "W38F2001",
            state: { mode: +data.AppStatusID ? 'view' : 'edit', ProposalID: data.ProposalID, ProBatchID: data.ProBatchID }
        });
    };

    onDelete = data => {
        const { ProBatchID, ProposalID } = data;

        const param = {
            ProBatchID,
            ProposalID,
            Mode: 0
        };

        Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"), () => {
            this.props.W38F2000Actions.deleteGrid(param, error => {
                if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else {
                    Config.notify.show(
                        "success",
                        Config.lang("DHR_Xoa_thanh_cong"),
                        3000
                    );
                    this.loadDataGrid();
                }
            });
        });
    };

    renderAction = e => {
        const { data } = e.row;
        return (
            <div className={"display_row align-center"}>
                <IconButton
                    aria-label="view"
                    size="small"
                    onClick={() => this.onView(e)}
                >
                    <Icons name={"view"}/>
                </IconButton>
                <Divider
                    orientation="vertical"
                    style={{ width: 2, height: 11, margin: "0 5px" }}
                />
                <IconButton
                    disabled={this.state.iPermission < 4 || +data.AppStatusID !== 0}
                    aria-label="delete"
                    size="small"
                    onClick={() => this.onDelete(data)}
                >
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        );
    };

    filterChange = (key, data) => {
        if (!key) return false;
        const { value, target } = data;
        const { error } = this.state;
        switch (key) {
            case "FromDate":
                this.setState({ FromDate: value });
                break;
            case "ToDate":
                this.setState({ ToDate: value });
                break;
            case "AppStatusID":
            case "EmployeeID":
            case "TrainingCourseID":
                this.filter[key] = !Config.isEmpty(value, true) ? value : "";
                break;
            case "FromProNumber": case "ToProNumber": {
                this.filter[key] = !Config.isEmpty(target.value, true) ? +target.value : "";
                    if (this.filter.FromProNumber && this.filter.ToProNumber && this.filter.FromProNumber > this.filter.ToProNumber) {
                        this.setState({error: {...error, ToProNumber: Config.lang('DHR_Phai_lon_hon_gia_tri_tu')}, disabledFilter: true});
                    } else if (this.state.error.ToProNumber) {
                        this.setState({error: {...error, ToProNumber: ''}, disabledFilter: this.state.error.ToProCost ? true : false})
                    }
                break;
            }
            case "FromProCost": case "ToProCost": {
                this.filter[key] = !Config.isEmpty(target.value, true) ? +target.value : "";
                if (this.filter.FromProCost && this.filter.ToProCost && this.filter.FromProCost > this.filter.ToProCost) {
                    this.setState({error: {...error, ToProCost: Config.lang('DHR_Phai_lon_hon_gia_tri_tu')}, disabledFilter: true});
                } else if (this.state.error.ToProCost) {
                    this.setState({error: {...error, ToProCost: ''}, disabledFilter: this.state.error.ToProNumber ? true : false})
                }
                break;
            }
            default:
                break;
        }
    };

    renderFilter = () => {
        const { getCboAppStatus, getCboTraining, classes } = this.props;
        const { FromDate, ToDate, employeeLoading, disabledFilter, error, dataCboEmployees } = this.state;

        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className={classes.divText}>
                                            {Config.lang("DHR_Tu")}
                                        </div>
                                        <DateBoxPicker
                                            dateBoxProps={{ max: ToDate && ToDate }}
                                            style={{ marginLeft: 5 }}
                                            onValueChanged={e => this.filterChange("FromDate", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={"DD/MM/YYYY"}
                                            value={FromDate && FromDate}
                                        />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className={classes.divText}>
                                            {Config.lang("DHR_Den").toLowerCase()}
                                        </div>
                                        <DateBoxPicker
                                            dateBoxProps={{ min: FromDate && FromDate }}
                                            style={{ marginLeft: 5 }}
                                            onValueChanged={e => this.filterChange("ToDate", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={"DD/MM/YYYY"}
                                            value={ToDate && ToDate}
                                        />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                        label={Config.lang("DHR_Trang_thai_duyet")}
                                        dataSource={getCboAppStatus}
                                        displayExpr={"AppStatusName"}
                                        valueExpr={"AppStatusID"}
                                        onValueChanged={e => this.filterChange("AppStatusID", e)}
                                        showClearButton={true}
                                        value={this.filter.AppStatusID || ''}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} >
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={this.filter.EmployeeID || ''}
                                            loading={employeeLoading}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Nguoi_lap')}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                if (this.filterCboEmployees.timer) clearTimeout(this.filterCboEmployees.timer);
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.timer = setTimeout(() => {
                                                    this.filterCboEmployees.skip = 0;
                                                    this.filterCboEmployees.limit = 20;
                                                    this.loadCboEmployees(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} >
                                        <Combo
                                            label={Config.lang('DHR_Khoa_dao_tao')}
                                            dataSource={getCboTraining}
                                            displayExpr={"TrainingCourseName"}
                                            valueExpr={'TrainingCourseID'}
                                            onValueChanged={e => this.filterChange("TrainingCourseID", e)}
                                            showClearButton={true}
                                            value={this.filter.TrainingCourseID || ''}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className={classes.divText}>
                                            {Config.lang("DHR_Tu")}
                                        </div>
                                        <TextField
                                            //error={error && error["PerformancePeriod"] && 'Bắt buộc nhập'}
                                            variant={"outlined"}
                                            size={'small'}
                                            // margin={"normal"}
                                            style={{ marginLeft: 5, marginTop: 4 }}
                                            label={Config.lang("DHR_So_luong_de_xuat")}
                                            InputProps={{
                                                min: 0,
                                                inputComponent: NumberFormatCustom
                                            }}
                                            onChange={e => this.filterChange("FromProNumber", e)}
                                            fullWidth
                                        />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className={classes.divText}>
                                            {Config.lang("DHR_Den").toLowerCase()}
                                        </div>
                                        <TextField
                                            error={error && error["ToProNumber"]}
                                            variant={"outlined"}
                                            size={'small'}
                                            // margin={"normal"}
                                            style={{ marginLeft: 5, marginTop: 4 }}
                                            label={Config.lang("DHR_So_luong_de_xuat")}
                                            helperText={error && error["ToProNumber"]}
                                            InputProps={{
                                                min: 0,
                                                inputComponent: NumberFormatCustom
                                            }}
                                            onChange={e => this.filterChange("ToProNumber", e)}
                                            fullWidth
                                        />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginTop: 13}}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className={classes.divText}>
                                            {Config.lang("DHR_Tu")}
                                        </div>
                                        <TextField
                                            //error={error && error["PerformancePeriod"] && 'Bắt buộc nhập'}
                                            variant={"outlined"}
                                            size={'small'}
                                            style={{ marginLeft: 5, marginTop: 4 }}
                                            label={Config.lang("DHR_Tong_chi_phi")}
                                            InputProps={{
                                                min: 0,
                                                inputComponent: NumberFormatCustom
                                            }}
                                            onChange={e => this.filterChange("FromProCost", e)}
                                            fullWidth
                                        />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className={classes.divText}>
                                            {Config.lang("DHR_Den").toLowerCase()}
                                        </div>
                                        <TextField
                                            error={error && error["ToProCost"]}
                                            variant={"outlined"}
                                            size={'small'}
                                            style={{ marginLeft: 5, marginTop: 4 }}
                                            label={Config.lang("DHR_Tong_chi_phi")}
                                            helperText={error && error["ToProCost"]}
                                            InputProps={{
                                                min: 0,
                                                inputComponent: NumberFormatCustom
                                            }}
                                            onChange={e => this.filterChange("ToProCost", e)}
                                            fullWidth
                                        />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonCustom
                                    name={Config.lang("DHR_Tim_kiem")}
                                    size={"large"}
                                    className={classes.btnFilter}
                                    disabled={disabledFilter}
                                    color={"primary"}
                                    variant={"text"}
                                    onClick={this.onSearch}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    componentDidMount = async() => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.loadDataGrid();
        this.loadCboAppStatus();
        this.loadCboEmployees();
        this.getCboTraining();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {dataGrid} = this.props;
        Config.callChildForm({
            FormID: "W38F2000",
            // ID: voucher_id,
            keyExpr: "ProposalID",
            data: dataGrid && dataGrid.rows ? dataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.onView({ row: { data: data } })
        }, this.props);
    }

    getCboTraining = () => {
        const param = {
            DivisionID: Config.getDivisionID(),
            TrainingFieldID: '',
            TrainingCourseID: '',
            Language: Config.language || "84",
        };

        this.props.W38F2000Actions.getCboTraining(param, error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", '[GET_COMBO_TRANINING] ' + message);
                return false;
            }
        });
    }

    loadDataGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { FromDate, ToDate } = this.state;
        const { AppStatusID, EmployeeID, TrainingCourseID, FromProNumber, ToProNumber, ToProCost, FromProCost, limit, skip} = this.filter;
        const param = {
            TransID: VoucherID || "",
            DivisionID: Config.getDivisionID(),
            FormID: "W38F2000",
            FromDate: FromDate || null,
            ToDate: ToDate || null,
            AppStatusID: AppStatusID,
            EmployeeID: EmployeeID ? EmployeeID.EmployeeID : '',
            TrainingCourseID: TrainingCourseID,
            FromProNumber: FromProNumber,
            ToProNumber: ToProNumber,
            FromProCost: FromProCost,
            ToProCost: ToProCost,
            Language: Config.language || "84",
            skip: skip,
            limit: limit
        };
        this.setState({ gridLoading: true });
        this.props.W38F2000Actions.loadDataGrid(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "STANDARD",
            Language: Config.language || 84
        };

        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W38F2000",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setState({ employeeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    render() {
        const { dataGrid } = this.props;
        const { iPermission, gridLoading } = this.state;
        if (iPermission <= 0) return null;
        const { skip, limit } = this.filter;
        return (
            <div>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("DHR_De_xuat_dao_tao")}>
                    <ButtonGeneral
                        name={Config.lang("DHR_Them")}
                        typeButton={"add"}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        disabled={iPermission < 2}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <GridContainer
                    style={{ border: "none" }}
                    dataSource={dataGrid && dataGrid.rows}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    rowAlternationEnabled={false}
                    noDataText={Config.lang("DHR_No_data")}
                    onDbCellClick={e => this.onView(e)}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    totalItems={dataGrid && Number(dataGrid.total)}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile} />
                    <Column cellRender={this.renderInfo} />
                    <Column cellRender={this.renderStatus} />
                    <Column cellRender={this.renderAction} width={100} />
                </GridContainer>
            </div>
        );
    }
}

class NumberFormatCustom extends React.Component {
    render() {
        const {name, inputRef, onChange, ...props} = this.props;
        return (
            <NumberFormat
                {...props}
                // value={value}
                getInputRef={inputRef}
                thousandSeparator={true}
                onValueChange={(e) => {
                    if (onChange) {
                        onChange({
                            target: {
                                name: name,
                                value: e.value
                            }
                        })
                    }
                }}
            />
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.general.getCboAppStatus,
            getCboTraining: state.W38F2000.getCboTraining,
            dataGrid: state.W38F2000.dataGrid
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W38F2000Actions: bindActionCreators(W38F2000Actions, dispatch)
        })
    ),
    withStyles(styles)
)(W38F2000);
