/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 06/30/2020
 * @update 06/30/2020
 * @Example
 */

import React from "react";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { Image, FormGroup, Row, Col } from "react-bootstrap";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import moment from "moment";
import { browserHistory } from "react-router";
import UserImage from "../../../common/user/user-image";

import * as w39F1000Actions from "../../../../redux/W3X/W39F1000/W39F1000_actions";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import Icons from "../../../common/icons/";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000"
    }
};

class W39F1000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            PerformanceDateTo: "",
            PerformanceDateFrom: "",
        };
        this.filter = {
            AppStatusID: "%",
            skip: 0,
            limit: 10
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W39F1000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID }
        });
    };

    renderEmpProfile = e => {
        const { classes } = this.props;
        const { data } = e.row;

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>
                    <div className={classes.divText}>{data.DepartmentName || ""}</div>
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                            {data.VoucherDate}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const { data } = e.row;
        const { classes } = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {data.TransTypeName && (
                    <div>
                        {Config.lang("DHR_Loai_danh_gia")}: {data.TransTypeName}
                    </div>
                )}
                {data.PerformanceDateFrom && data.PerformanceDateTo && Number(data.PerformancePeriod) >= 0 && (
                    <div>
                        {Config.lang("DHR_Chu_ky")}: {data.PerformancePeriod} <span style={{ textTransform: "lowercase" }}>{Config.lang('DHR_Thang')},</span> {data.PerformanceDateFrom} - {data.PerformanceDateTo}
                    </div>
                )}
                {data.Note && (
                    <div>
                        {Config.lang("DHR_Ghi_chu")}: {data.Note}
                    </div>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const { getCboAppStatus } = this.props;
        const { data } = e.row;
        const cboStatus = getCboAppStatus && getCboAppStatus.find(st => st.AppStatusID === data.AppStatusID);
        const status = {
            AppStatusID: data.AppStatusID,
            AppStatusName: cboStatus && cboStatus.AppStatusName ? cboStatus.AppStatusName : "",
        };
        return (
            <Status data={status} />
        );
    };

    onAdd = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1005",
            state: { mode: 'add' }
        });
    };

    onView = e => {
        const { data } = e.row;
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1005",
            state: { mode: data.AppStatusID ? 'view' : 'edit', data: data }
        });
    };

    onDelete = data => {
        const { EvaluationVoucherID } = data;

        const param = {
            TransID: EvaluationVoucherID,
            FormID: 'W39F1000',
            Mode: 0
        };

        Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"), () => {
            this.props.w39F1000Actions.deleteDataGrid(param, error => {
                if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else {
                    Config.notify.show(
                        "success",
                        Config.lang("DHR_Xoa_thanh_cong"),
                        3000
                    );
                    this.loadDataGrid();
                }
            });
        });
    };

    renderAction = e => {
        const { data } = e.row;
        return (
            <div className={"display_row align-center"}>
                <IconButton
                    aria-label="view"
                    size="small"
                    onClick={() => this.onView(e)}
                >
                    <Icons name={"view"}/>
                </IconButton>
                <Divider
                    orientation="vertical"
                    style={{ width: 2, height: 11, margin: "0 5px" }}
                />
                <IconButton
                    disabled={this.state.iPermission < 4 || !!data.IsDelete || !!data.StatusID}
                    aria-label="delete"
                    size="small"
                    onClick={() => this.onDelete(data)}
                >
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        );
    };

    filterChange = (key, data) => {
        const { value } = data;
        if (!key) return false;
        switch (key) {
            case "DateFrom":
                this.setState({ PerformanceDateFrom: value });
                break;
            case "DateTo":
                this.setState({ PerformanceDateTo: value });
                break;
            case "AppStatusID":
                this.filter.AppStatusID = !Config.isEmpty(value, true) ? value : "%";
                break;
            default:
                break;
        }
    };

    renderFilter = () => {
        const { getCboAppStatus, classes } = this.props;
        const { AppStatusID } = this.filter;
        const { PerformanceDateTo, PerformanceDateFrom } = this.state;

        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                          <FormGroup style={{ marginBottom: "7px" }}>
                            <Row>
                              <Col xs={12} sm={6} md={6} lg={6}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <div className={classes.divText}>
                                    {Config.lang("DHR_Tu")}
                                  </div>
                                  <DateBoxPicker
                                    dateBoxProps={{ max: PerformanceDateTo ? PerformanceDateTo :  moment() }}
                                    style={{ marginLeft: "5px" }}
                                    onValueChanged={e => this.filterChange("DateFrom", e)}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    placeholder={"DD/MM/YYYY"}
                                    value={PerformanceDateFrom && PerformanceDateFrom}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} sm={6} md={6} lg={6}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <div className={classes.divText}>
                                    {Config.lang("DHR_Den").toLowerCase()}
                                  </div>
                                  <DateBoxPicker
                                    dateBoxProps={{ min: PerformanceDateFrom }}
                                    style={{ marginLeft: "5px" }}
                                    onValueChanged={e => this.filterChange("DateTo", e)}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    placeholder={"DD/MM/YYYY"}
                                    value={PerformanceDateTo && PerformanceDateTo}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup style={{ marginBottom: "7px" }}>
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Combo
                                  label={Config.lang("DHR_Trang_thai_duyet")}
                                  dataSource={getCboAppStatus}
                                  displayExpr={"AppStatusName"}
                                  valueExpr={"AppStatusID"}
                                  onValueChanged={e => this.filterChange("AppStatusID", e)}
                                  showClearButton={true}
                                  value={AppStatusID === "%" ? "" : AppStatusID}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "38px",
                              paddingBottom: "40px"
                            }}
                          >
                            <ButtonGeneral
                              name={Config.lang("DHR_Tim_kiem")}
                              color={"primary"}
                              variant={"text"}
                              style={{ textTransform: "uppercase" }}
                              size={"large"}
                              onClick={this.onSearch}
                            />
                          </div>
                        </>
                    );
                }}
            />
        );
    };

    componentDidMount = async() => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.loadDataGrid();
        this.loadCboAppStatus();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {dataGrid} = this.props;
        Config.callChildForm({
            FormID: "W39F1000",
            // ID: voucher_id,
            keyExpr: "EvaluationVoucherID",
            data: dataGrid && dataGrid.rows ? dataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.onView({row: { data: data }})
        }, this.props);
    }

    loadDataGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { PerformanceDateTo, PerformanceDateFrom } = this.state;
        const { AppStatusID, limit, skip} = this.filter;
        const param = {
            TransID: VoucherID || "",
            DivisionID: Config.getDivisionID(),
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            PerformanceDateFrom: PerformanceDateFrom || null,
            PerformanceDateTo: PerformanceDateTo || null,
            AppStatusID: AppStatusID,
            FormID: "W39F1000",
            skip: skip,
            limit: limit
        };
        this.setState({ gridLoading: true });
        this.props.w39F1000Actions.loadDataGrid(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W39F1000",
            Language: Config.language || 84
        };

        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    render() {
        const { dataGrid } = this.props;
        const { iPermission, gridLoading } = this.state;
        if (iPermission <= 0) return null;
        const { skip, limit } = this.filter;
        return (
          <div>
            <div className={"hidden"}>{this.renderFilter()}</div>
            <ActionToolbar title={Config.lang("DHR_Danh_gia_Nhan_vien")}>
                <ButtonGeneral
                    name={Config.lang("DHR_Them")}
                    typeButton={"add"}
                    style={{ textTransform: "uppercase" }}
                    size={"large"}
                    onClick={this.onAdd}
                />
            </ActionToolbar>
            <GridContainer
                style={{ border: "none" }}
                dataSource={dataGrid && dataGrid.rows}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                rowAlternationEnabled={false}
                noDataText={Config.lang("DHR_No_data")}
                onDbCellClick={e => this.onView(e)}
                loading={gridLoading}
                hoverStateEnabled={true}
                totalItems={dataGrid && Number(dataGrid.total)}
                itemPerPage={limit}
                skipPerPage={skip}
                typePaging={"remote"}
                height={Config.getHeightGrid()}
                onChangePage={this.onChangePage}
                onChangePerPage={this.onChangePerPage}
            >
                <Column cellRender={this.renderEmpProfile} />
                <Column cellRender={this.renderInfo} />
                <Column cellRender={this.renderStatus} />
                <Column cellRender={this.renderAction} />
            </GridContainer>
          </div>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.general.getCboAppStatus,
            getCboEmployees: state.general.getCboEmployees,
            dataGrid: state.W39F1000.dataGrid
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w39F1000Actions: bindActionCreators(w39F1000Actions, dispatch)
        })
    ),
    withStyles(styles)
)(W39F1000);
