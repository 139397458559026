/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 06/07/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import * as w39f1005Actions from "../../../../redux/W3X/W39F1005/W39F1005_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup, Image } from "react-bootstrap"
import ButtonGeneral from "../../../common/button/button-general";
import UserName from "../../../common/user/user-name";
import UserImage from "../../../common/user/user-image";
import withStyles from "@material-ui/core/styles/withStyles";
import { Combo, MForm, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonCustom from "../../../common/button/button-custom";
import InlineSVG from "react-inlinesvg";
import moment from "moment";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import { browserHistory } from "react-router";
import { FormControl, InputLabel, Typography } from "@material-ui/core";
import { LoadPanel } from 'devextreme-react/load-panel';
import {ButtonIcon} from "diginet-core-ui/components";
import {Rating} from "diginet-core-ui/components";
import {Approval} from "diginet-core-ui/icons";

const styles = {
    iconButton: {
        width: 30,
        height: 30,
        position: "absolute",
        left: "1rem",
        top: 0
    },
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        color: 'blue' //"rgb(29, 76, 114)"
    },
    divIDText: {
        fontSize: "12px",
        fontWeight: 400,
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000"
    },
    justifyContentCenter: {
        display: 'flex',
        justifyContent: 'center!important',
        alignItems: 'center!important'
    },
    dataDetail: {
        height: '100%',
        maxHeight: 450,
        overflowY: 'auto'
    }
};
class W39F1005 extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.state = {
            isPermission: {},
            isSaving: false,
            isSaved: false,
            isUpdated: false,
            disabled: false,
            showEmployeeSelect: false,
            evaluationDataDetails: [],
            evaluationDeleted: [],
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataCboTransType: {
                rows: [],
                total: 0
            },

            TransType: null,
            DutyID: [],
            Project: [],
            PerformancePeriod: "",
            PerformanceDateTo: "",
            PerformanceDateFrom: "",
            StatusID: 0,
            Note: "",

            error: {
                TransTypeID: "",
                ProjectID: "",
                PerformancePeriod: "",
                PerformanceDateTo: "",
                PerformanceDateFrom: "",
                StatusID: ""
            },

            gridLoading: false,
            transTypeLoading: false,
            projectLoading: false,
            dataDefaults: {},
        };
        this.isRenderModalW09F6000 = false;
        this.filter = {
            limit: 10,
            skip: 0
        };
        this.filterCboProjects = {
            timer: null,
            skip: 0,
            limit: 20,
            strSearch: ""
        };
        this.filterCboTransType = {
            timer: null,
            skip: 0,
            limit: 20,
            strSearch: ""
        };
    }

    async loadPermission() {
        await this.props.generalActions.getPermission(["W39F1000", "W39F1006"], (arrPer) => {
            if (arrPer !== 0) {
                let isPermission = {};
                arrPer.forEach(per => {
                    if (per.hasOwnProperty('FormID') && per.hasOwnProperty('IsPermission')) {
                        isPermission[per.FormID] = per.IsPermission;
                    }
                });
                this.setState({isPermission: isPermission});
            }
        });
    };

    loadCboProjects = (isReset) => {
        const params = {
            HostID: "",
            FormID: "W39F1005",
            Language: Config.language || "84",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setState({ projectLoading: true });
        this.props.generalActions.getCboProjects(params, (errors, data) => {
            this.setState({ projectLoading: false });
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W39F1000",
            Language: Config.language || 84
        };

        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboTransType = (isReset) => {
        const params = {
            TransTypeID: '',
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            FormID: 'W39F1005',
            skip: this.filterCboProjects.skip,
            limit: this.filterCboTransType.limit,
            search: this.filterCboTransType.strSearch
        };
        this.setState({ transTypeLoading: true });
        this.props.w39f1005Actions.loadCboTransType(params, (errors, data) => {
            this.setState({ transTypeLoading: false });
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const { dataCboTransType } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboTransType: {
                        rows: isReset ? rows : dataCboTransType.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadMaster = () => {
        const { data } = this.getInfo();
        this.setState({
            TransType: { TransTypeID: data.TransTypeID, TransTypeName: data.TransTypeName ? data.TransTypeName : data.TransTypeID?.trim()},
            DutyID: data.DutyID || [],
            Project: { ProjectID: data.ProjectID, ProjectName: data.ProjectName ? data.ProjectName : data.ProjectID?.trim() },
            PerformancePeriod: data.PerformancePeriod ? data.PerformancePeriod : '',
            PerformanceDateFrom: data.PerformanceDateFrom ? moment(data.PerformanceDateFrom, 'DDMMYYYY').format("YYYY-MM-DD") : '',
            PerformanceDateTo: data.PerformanceDateTo ? moment(data.PerformanceDateTo, 'DDMMYYYY').format("YYYY-MM-DD") : '',
            StatusID: +data.StatusID,
            Note: data.Note
        }, () => {
            this.loadDetail(data.EvaluationVoucherID);
        });
    };

    loadDetail = (EvaluationVoucherID) => {
        if (!EvaluationVoucherID) return;
        const params = {
            EvaluationVoucherID: EvaluationVoucherID
        };
        this.setState({ gridLoading: true });
        this.props.w39f1005Actions.loadDetail(params, (errors, dataDetail) => {
            this.setState({ gridLoading: false });
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (dataDetail) {
                this.setState({
                    evaluationDataDetails: dataDetail
                });
            }
        });
    };

    getInfo = (flag) => {
        const { location } = this.props;
        if (location && location.state) {
            return {
                mode: this.state?.isSaved ? 'edit' : location.state.mode,
                data: location.state.data ? location.state.data : null
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W39F1000");
                return null;
            }
            return false;
        }
    };

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        await this.loadPermission();
        if (this.state.isPermission["W39F1000"] <= 0) return;
        const { mode, data } = this.getInfo();
        this.loadCboProjects();
        this.loadCboAppStatus();
        this.loadCboTransType();
        if (mode && mode !== 'add') {
            this.loadMaster();
            if (mode === "view" && data.AppStatusID !== 0) {
                this.setState({ disabled: true });
            }
        }
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    handleChange = (key, e) => {
        if (!key) return false;
        if (!this.state.isUpdated) {
            this.setState({isUpdated: true})
        }
        switch (key) {
            case "TransTypeID": {
                this.setState({
                    TransType: e.value,
                    DutyID: e.value.DutyID
                }, () => {
                    setTimeout(() => {
                        this.updateTransTime('TransType', e.value);
                    }, 0);
                    this.loadSelectedEmployees(2);
                });
                break;
            }
            case "PerformancePeriod": {
                const value = e.target.value;
                if ((+value && value.includes('.') !== true) || value === '') {
                    if (+value < 10000) {
                        this.setState({
                            PerformancePeriod: value
                        }, () => {
                            // If value === '' then it will not updateTransTime
                            if (+value) {
                                setTimeout(() => {
                                    this.updateTransTime('PerformancePeriod', value);
                                }, 0);
                            }
                        })
                    } else {
                        Config.popup.show('INFO', Config.lang('So_thang_khong_duoc_qua_lon'));
                    }
                }
                break;
            }
            case "PerformanceDateFrom": {
                this.setState({
                    PerformanceDateFrom: e.value
                }, () => {
                    setTimeout(() => {
                        this.updateTransTime('PerformanceDateFrom', e.value);
                    }, 0);
                });
                break;
            }
            case "PerformanceDateTo": {
                this.setState({
                    PerformanceDateTo: e.value
                }, () => {
                    setTimeout(() => {
                        this.updateTransTime('PerformanceDateTo', e.value);
                    }, 0);
                });
                break;
            }
            case 'StatusID': {
                this.setState({
                    StatusID: e.value
                });
                break;
            }
            case "Note": {
                const value = e.target.value;
                setTimeout(() => {
                    this.setState({
                        Note: value
                    })
                }, 0);
                break;
            }
            default:
                break;
        }
    };

    updateTransTime = (key, data = {}) => {
        if (data) {
            let { PerformancePeriod, PerformanceDateFrom, PerformanceDateTo } = this.state;
            switch (key) {
                case 'PerformancePeriod': {
                    if (PerformanceDateFrom) {
                        // Update PerformanceDateTo: To = From + NumMonths + 1 (day)
                        PerformanceDateTo = moment(PerformanceDateFrom).add(data, 'months').subtract(1, 'day').format('YYYY-MM-DD');
                        this.setState({
                            PerformanceDateTo
                        });
                    } else if (PerformanceDateTo) {
                        // Update PerformanceDateFrom: From = To - NumMonths + 1 (day)
                        PerformanceDateFrom = moment(PerformanceDateTo).subtract(data, 'months').add(1, 'day').format('YYYY-MM-DD');
                        this.setState({
                            PerformanceDateFrom
                        });
                    }
                    break;
                }
                case 'PerformanceDateFrom':
                    if (PerformancePeriod) {
                        // Update PerformanceDateTo: To = From + NumMonths + 1 (day)
                        PerformanceDateTo = moment(data).add(PerformancePeriod, 'months').subtract(1, 'day').format('YYYY-MM-DD');
                        this.setState({
                            PerformanceDateTo
                        });
                    } else if (PerformanceDateTo) {
                        // Update PerformancePeriod: NumMonths = [To + 1 (day)] - From
                        PerformancePeriod = moment(PerformanceDateTo).add(1, 'day').diff(data, 'months');
                        this.setState({
                            PerformancePeriod
                        });
                    }
                    break;
                case 'PerformanceDateTo': {
                    PerformanceDateTo = moment(data);
                    if (PerformanceDateFrom) {
                        // Update PerformancePeriod: NumMonths = [To + 1 (day)] - From
                        PerformancePeriod = PerformanceDateTo.add(1, 'day').diff(PerformanceDateFrom, 'months');
                        this.setState({
                            PerformancePeriod
                        });
                    } else if (PerformancePeriod) {
                        // Update PerformanceDateFrom: From = To - NumMonths + 1 (day)
                        PerformanceDateFrom = PerformanceDateTo.subtract(PerformancePeriod, 'months').add(1, 'day').format('YYYY-MM-DD');
                        this.setState({
                            PerformanceDateFrom
                        });
                    }
                  break;
                }
                default: {
                    this.setState({
                        PerformancePeriod: data.PerformancePeriod || '',
                        PerformanceDateTo: moment(data.PerformanceDateTo).format('YYYY-MM-DD') || '',
                        PerformanceDateFrom: moment(data.PerformanceDateFrom).format('YYYY-MM-DD') || ''
                    });
                    break;
                }
            }
        }
    };

    openEmployeeSelect = () => {
        const { DutyID, Project, evaluationDataDetails } = this.state;
        const dataSelected = evaluationDataDetails.map(rs => {
            const user = Config.getUser({ EmployeeID: rs.EmployeeID });
            return { ...rs, ...user }
        });
        this.setState({
            showEmployeeSelect: true,
            dataDefaults: {
                DutyID: DutyID,
                Project: Project && Project.ProjectID ? [Project] : [],
                dataSelected: dataSelected,
                selectedRowKeys: evaluationDataDetails.map(e => e.EmployeeID),
                isLoaded: true
            }
        });
        this.isRenderModalW09F6000 = true;
    };

    closeEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: false, dataDefaults: null });
    };

    onSelectedEmployee = (EmployeeIDs) => {
        const { evaluationDataDetails } = this.state;
        const evaluationDataEmployeeID = evaluationDataDetails.map(e => e.EmployeeID);
        EmployeeIDs.forEach(id => {
            if (evaluationDataEmployeeID.indexOf(id) < 0)  {
                this.setState({ gridLoading: true });
                this.loadSelectedEmployees(1);
                if (!this.state.isUpdated) {
                    this.setState({ isUpdated: true });
                }
                return this.closeEmployeeSelect();
            }
        });
        this.closeEmployeeSelect();
    };

    loadSelectedEmployees = (mode) => {
        const { TransType } = this.state;
        const params = {
            EvaluationVoucherID: "",
            FormID: "W39F1000",
            Mode: mode,
            TransTypeID: TransType?.TransTypeID || ""
        };
        this.props.w39f1005Actions.loadDetail(params, (errors, data) => {
            this.setState({gridLoading: false});
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const selectedEmployees = [...this.state.evaluationDataDetails];
                const listEmployees = selectedEmployees.map((e) => {
                    return e.EmployeeID;
                });
                if(mode === 1) {
                    if(data && data.length > 0) {
                        data.forEach((e) => {
                            if (listEmployees.indexOf(e.EmployeeID) < 0) {
                                e.IsNew = 1;
                                selectedEmployees.unshift(e);
                            }
                        });
                    }
                }
                if(mode === 2) {
                    if(data && data.length > 0) {
                        data.forEach((e) => {
                            e.IsNew = 1;
                        });
                    }

                }
                this.setState({
                    evaluationDataDetails: mode === 2 ? data : selectedEmployees
                });
            }
        });
    };

    onSave = () => {
        const { mode } = this.getInfo();
        const { TransType, StatusID, PerformancePeriod, PerformanceDateFrom } = this.state;

        //validate form...
        const validateRules = [
            {
                name: "StatusID",
                rules: "isRequired",
                value: StatusID
            }
        ];
        if (mode === 'add') {
            validateRules.push(...[
                {
                    name: "TransTypeID",
                    rules: "isRequired",
                    value: TransType?.TransTypeID
                },
                {
                    name: "PerformancePeriod",
                    rules: "isRequired",
                    value: PerformancePeriod
                },
                {
                    name: "PerformanceDateFrom",
                    rules: "isRequired",
                    value: PerformanceDateFrom
                }
            ]);
        }
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length !== 0) { //Neu co loi validate
            this.setStateErrorText(validateForm);
            return false;
        } else {
            Config.popup.show('YES_NO', Config.lang("Ban_co_chac_muon_luu_khong?"), () => {
                this._saveData();
            });
        }
    };

    _saveData = () => {
        //const dataSource = data ? data : this.dataGrid.instance.option('dataSource');
        const { TransType, Project, evaluationDeleted, evaluationDataDetails, PerformancePeriod, PerformanceDateTo, PerformanceDateFrom, StatusID, Note } = this.state;
        const { mode, data } = this.getInfo();
        this.setState({ isSaving: true, loading: true }); //set status..

        const dataEmployee = [];
        evaluationDataDetails.forEach(e => {
            if (e.IsEvaluation === 0 && e.IsNew) {
                dataEmployee.push({
                    EmployeeID: e.EmployeeID,
                    IsEvaluation: e.IsEvaluation,
                    IsAppStatus: e.IsAppStatus,
                    TransID: e.TransID,
                    VoucherDate: e.VoucherDate,
                    Deleted: e.Deleted || 0,
                });
            }
        });

        if (evaluationDeleted.length) {
            evaluationDeleted.forEach(e => {
                dataEmployee.push({
                    ...e,
                    Deleted: 1
                })
            })
        }

        const params = {
            FormID: "W39F1005",
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            DivisionID: Config.getDivisionID(),
            NoteU: Note || "",
            TransTypeID: TransType?.TransTypeID,
            PerformancePeriod: PerformancePeriod || 0,
            PerformanceDateFrom: PerformanceDateFrom || '',
            PerformanceDateTo: PerformanceDateTo || '',
            ProjectID: Project?.ProjectID || '',
            StatusID: StatusID || 0,
            Language: Config.language || "84",
            data: JSON.stringify(dataEmployee)
        };
        if (mode === 'edit') {
            params["EvaluationVoucherID"] = data?.EvaluationVoucherID || this.state.isSaved || "";
        }
        this.props.w39f1005Actions[mode](params, (errors, dataDetail) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({ isSaving: false, loading: false });
                return false;
            }
            if (dataDetail) {
                const { isSaved } = this.state;
                const EvaluationVoucherID = isSaved ? isSaved : mode === 'add' ? dataDetail.EvaluationVoucherID : data.EvaluationVoucherID;
                this.setState({ isSaving: false, loading: false, disabled: true, isSaved: EvaluationVoucherID, isUpdated: false, gridLoading: true, evaluationDeleted: [] });
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                // ReLoadDetal

                const dataT = params;
                dataT.PerformanceDateFrom = moment(dataT.PerformanceDateFrom, 'YYYY-MM-DD').format("DD/MM/YYYY");
                dataT.PerformanceDateTo = moment(dataT.PerformanceDateTo, 'YYYY-MM-DD').format("DD/MM/YYYY");
                dataT.EvaluationVoucherID = EvaluationVoucherID;
                if(dataT.NoteU) dataT.Note = dataT.NoteU;

                browserHistory.replace({
                    pathname: Config.getRootPath() + "W39F1005",
                    state: {
                        mode: "edit",
                        data: dataT,
                    }
                });

                this.loadDetail(EvaluationVoucherID);

            } else {
                this.setState({ isSaving: false });
            }
        });
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W39F1000");
    };

    onDelete = ({index, EmployeeID}) => {
        Config.popup.show('YES_NO', Config.lang("Ban_co_chac_muon_xoa?"), () => {
            this.props.w39f1005Actions.removeItem(index, error => {
                if (error) {
                    Config.popup.show('INFO', Config.lang("Loi_chua_xac_dinh"));
                }
                // Remove EmployeeID out EmployeeIDs state
                const { evaluationDeleted, evaluationDataDetails } = this.state;

                const newEvaluationDeleted = evaluationDeleted.concat(evaluationDataDetails.filter(e => !e.IsNew && e.EmployeeID === EmployeeID));

                this.setState({
                    evaluationDeleted: newEvaluationDeleted,
                    evaluationDataDetails: evaluationDataDetails.filter(e => e.EmployeeID !== EmployeeID),
                    isUpdated: true
                });
            });
        });
    };

    onDetail = e => {
        const { mode, data } = this.getInfo();
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1011",
            state: {
                EmployeeID: e.EmployeeID,
                TransID: e.TransID,
                EvaluationVoucherID: e.EvaluationVoucherID,
                FormID: "W39F1005",
                FormPermission: "W39F1000",
                IsSave: e.IsSave,
                UserLevel: e.UserLevel,
                ApproveLevel: e.ApproveLevel,
                Mode: 0,
                mode: mode,
                data: data
            }
        });
    };

    renderEmpProfile = props => {
        const { classes } = this.props;
        const { data } = props;

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onDetail(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data} onClick={() => this.onDetail(data)}/></div>
                    <div className={classes.divIDText}>{data.EmployeeID || ""}</div>
                    <div className={classes.divText}>{data.DepartmentName || ""}</div>
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                            {data.VoucherDate}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderAction = props => {
        const { data, index } = props;
        const { data: dataMaster } = this.getInfo();

        const colorStarIcon = data.IsNew ? Config.coreTheme?.colors?.disabled : 'warning';

        return (
            <div className={"display_row align-center"}>
                <ButtonIcon disabled={this.state.isPermission["W39F1000"] < 4 || !!data.IsNew}
                            size="small"
                            viewType={"text"}
                            circular={true}
                            onClick={() => this.onDetail(data)}
                >
                    <Rating
                      quantity={1}
                      value={Number(data?.IsEvaluation)/2 ?? 0}
                      half={true}
                      readOnly
                      color={colorStarIcon}
                    />
                </ButtonIcon>

                {Number(data.IsApproved) === 1 &&
                  <Approval size={"small"}
                            color={data.IsAppStatus ? Config.coreTheme?.colors?.info : Config.coreTheme?.colors?.disabled}
                  />
                }

                <ButtonIcon size="small"
                            circular={true}
                            viewType={"text"}
                            name={"Delete"}
                            disabled={data.IsMultiLevel && data.IsEvaluation}
                            onClick={() => this.onDelete({index: index, EmployeeID: data.EmployeeID})}
                />

                {Number(dataMaster?.AppStatusID)  === 1 && this.state.isPermission["W39F1006"] > 0 &&
                    <ButtonIcon name={Number(data?.IsLocked) === 0 ? "Unlock" : "Lock"}
                                size={"small"}
                                circular={true}
                                viewType={"text"}
                                onClick={()=>this.onLock(data)}
                    />
                }
            </div>
        );
    };

    /** Action when click icon Lock or Unlock
     * @param data {object}: data one row detail
     */
    onLock = (data) => {
        const params = {
            IsLocked: +!data.IsLocked,
            EvaluationVoucherID: data.EvaluationVoucherID,
            TransID: data.TransID,
            EmployeeID: data.EmployeeID,
        };

        // call api update status lock
        this.props.w39f1005Actions.updateLock(params, (err, dataUpdate) => {
            if (err) {
                let message = err.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (dataUpdate?.Status === 1) {
                const msg = dataUpdate.Message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('ERROR', msg);
                return false;
            } else {
                this.loadDetail(data.EvaluationVoucherID);
                Config.notify.show('success', Config.lang("Cap_nhat_thanh_cong"), 2000);
            }
        });
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { mode, data } = this.getInfo();
        const { classes } = this.props;
        const { evaluationDataDetails, disabled, loading, error, showEmployeeSelect, gridLoading, isSaving, isUpdated,
            dataCboProjects, dataCboTransType, transTypeLoading, projectLoading,
            PerformancePeriod, PerformanceDateTo, PerformanceDateFrom, StatusID, dataDefaults } = this.state;

        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Lap_phieu_danh_gia_nhan_vien")}
                        onBack={this.onBack}
                    >
                        <div style={{marginLeft: 'auto'}}>
                            <ButtonCustom name={Config.lang('Chon_nhan_vien')}
                                color={"primary"}
                                variant={"custom"}
                                icon={<InlineSVG className={'btn-icon-svg'} src={require('../../../../assets/images/general/people.svg')} />}
                                style={{ textTransform: 'uppercase' }}
                                size={"large"}
                                className={"mgr5"}
                                disabled={isSaving || (mode !== 'add' && StatusID !== 0)}
                                onClick={this.openEmployeeSelect} />
                            <ButtonGeneral name={Config.lang('Luu')}
                                typeButton={'save'}
                                disabled={mode === 'view' || !isUpdated}
                                loading={isSaving}
                                style={{ textTransform: 'uppercase' }}
                                size={"large"}
                                onClick={this.onSave} />
                        </div>
                    </ActionToolbar>
                </FormGroup>
                {(showEmployeeSelect || this.isRenderModalW09F6000) && <W09F6000 open={showEmployeeSelect} dataDefaults={dataDefaults} FormID={"W39F1000"} onChosen={this.onSelectedEmployee} onClose={this.closeEmployeeSelect} />}
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Combo.LoadMore
                                            error={error && error['TransTypeID']}
                                            dataSource={dataCboTransType}
                                            skip={this.filterCboTransType.skip}
                                            limit={this.filterCboTransType.limit}
                                            displayExpr={'TransTypeName'}
                                            keyExpr={'TransTypeID'}
                                            valueExpr={'TransTypeID'}
                                            value={data?.TransTypeID}
                                            shrink={true}
                                            margin={"normal"}
                                            required
                                            disabled={loading || disabled || transTypeLoading || mode === 'edit'}
                                            loading={transTypeLoading}
                                            stylingMode={'underlined'}
                                            label={Config.lang('Loai_danh_gia')}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => {
                                                this.handleChange("TransTypeID", e)
                                            }}
                                            onInputChanged={(e) => {
                                                if (this.filterCboTransType.timer) clearTimeout(this.filterCboTransType.timer);
                                                this.filterCboTransType.strSearch = e.target.value;
                                                this.filterCboTransType.timer = setTimeout(() => {
                                                    this.filterCboTransType.skip = 0;
                                                    this.filterCboTransType.limit = 20;
                                                    this.loadCboTransType(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboTransType.skip = e.skip;
                                                this.filterCboTransType.limit = e.limit;
                                                this.loadCboTransType();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Combo.LoadMore
                                            error={error && error['ProjectID']}
                                            dataSource={dataCboProjects}
                                            skip={this.filterCboProjects.skip}
                                            limit={this.filterCboProjects.limit}
                                            displayExpr={'ProjectName'}
                                            keyExpr={'ProjectID'}
                                            valueExpr={'ProjectID'}
                                            value={data?.ProjectName}
                                            shrink={true}
                                            margin={"normal"}
                                            //required
                                            disabled={loading || disabled || projectLoading || mode === 'edit'}
                                            loading={projectLoading}
                                            stylingMode={'underlined'}
                                            showClearButton={true}
                                            label={Config.lang('Du_an')}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => {
                                                    this.setState({Project: e.value});
                                            }}
                                            onInputChanged={(e) => {
                                                if (this.filterCboProjects.timer) clearTimeout(this.filterCboProjects.timer);
                                                this.filterCboProjects.strSearch = e.target.value;
                                                this.filterCboProjects.timer = setTimeout(() => {
                                                    this.filterCboProjects.skip = 0;
                                                    this.filterCboProjects.limit = 20;
                                                    this.loadCboProjects(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProjects.skip = e.skip;
                                                this.filterCboProjects.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant={"standard"}
                                            margin={"normal"}
                                            fullWidth={true}>
                                            <InputLabel shrink={true} required>
                                                {Config.lang("So_thang")}
                                            </InputLabel>
                                            <Row>
                                                <Col xs={2} sm={2} md={2} lg={2}>
                                                    <TextField
                                                        error={error && error["PerformancePeriod"] && 'Bắt buộc nhập'}
                                                        variant={"standard"}
                                                        margin={"normal"}
                                                        disabled={loading || disabled || mode === 'edit'}
                                                        value={String(PerformancePeriod)}
                                                        required
                                                        InputLabelProps={{shrink: true}}
                                                        type={'number'}
                                                        inputProps={{ min: 1 }}
                                                        onChange={(e) => this.handleChange('PerformancePeriod', e)}
                                                        fullWidth
                                                    />
                                                </Col>
                                                <Col xs={5} sm={5} md={5} lg={5}>
                                                    <DateBoxPicker
                                                        error={error && error['PerformanceDateFrom']}
                                                        stylingMode={"underlined"}
                                                        margin={"normal"}
                                                        shrink={true}
                                                        required
                                                        width={"100%"}
                                                        value={PerformanceDateFrom}
                                                        disabled={loading || disabled || mode === 'edit'}
                                                        displayFormat={"dd/MM/yyyy"}
                                                        onValueChanged={(e) => this.handleChange('PerformanceDateFrom', e)}
                                                    />
                                                </Col>
                                                <Col xs={5} sm={5} md={5} lg={5}>
                                                    <DateBoxPicker
                                                        error={error && error['PerformanceDateTo']}
                                                        dateBoxProps={{ readOnly: true }}
                                                        stylingMode={"underlined"}
                                                        margin={"normal"}
                                                        shrink={true}
                                                        //required
                                                        width={"100%"}
                                                        value={PerformanceDateTo}
                                                        disabled={loading || disabled || mode === 'edit'}
                                                        displayFormat={"dd/MM/yyyy"}
                                                        onValueChanged={(e) => this.handleChange('PerformanceDateTo', e)}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Combo
                                            error={error && error['StatusID']}
                                            dataSource={this.props.getCboAppStatus}
                                            displayExpr={'AppStatusName'}
                                            valueExpr={'AppStatusID'}
                                            margin={"normal"}
                                            required
                                            stylingMode={'underlined'}
                                            disabled={loading || mode === 'view'}
                                            value={StatusID}
                                            shrink={true}
                                            label={Config.lang('Trang_thai_phieu_danh_gia')}
                                            onValueChanged={(e) => this.handleChange('StatusID', e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            label={Config.lang('Ghi_chu')}
                                            variant={"standard"}
                                            margin={"normal"}
                                            defaultValue={data?.Note}
                                            disabled={loading || mode === 'view'}
                                            InputLabelProps={{shrink: true}}
                                            onChange={e => this.handleChange('Note', e)}
                                            fullWidth
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <LoadPanel
                        position={{ my: 'center', of: null }}
                        visible={gridLoading}
                        showIndicator={true}
                        shading={false}
                        showPane={true}
                    />
                    {evaluationDataDetails.length > 0 &&
                        <div style={{border: '1px solid gray'}}>
                            <div className={"align-center flex-wrap"}
                                style={{ margin: '5px 20px', borderBottom: '0.5px solid #E0E0E0', height: 35}}
                            >
                                <Typography className={"action-toolbar-title"} style={{fontWeight: 600}} variant={"subtitle1"}>
                                    {Config.lang("Danh_sach_nhan_vien_danh_gia")} ({evaluationDataDetails.length})
                                </Typography>
                            </div>
                            <div className={classes.dataDetail}>
                                <Row style={{maxWidth: '100%', margin:0}}>
                                    {evaluationDataDetails.map((data, index) => {
                                        return (
                                            <Col xs={12} sm={12} md={6} lg={6} key={index}>
                                                <Row className={classes.justifyContentCenter}>
                                                    <Col xs={5} sm={5} md={6} lg={5}>
                                                        <this.renderEmpProfile data={data}/>
                                                    </Col>
                                                    <Col className={classes.justifyContentCenter} xs={5} sm={4} md={6} lg={5}>
                                                        <this.renderAction data={data} index={index}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        </div>
                    }
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect((state) => ({
    getCboAppStatus: state.general.getCboAppStatus,
    dataDetail: state.W39F1005.dataDetail,
}), (dispatch) => ({
    w39f1005Actions: bindActionCreators(w39f1005Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W39F1005);
