/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/14/2020
 * @Example
 */

import React from "react";
import { connect } from "react-redux";
import * as W29F3000Actions from "../../../../redux/W2X/W29F3000/W29F3000_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap"
import withStyles from "@material-ui/core/styles/withStyles";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import moment from "moment";
import { browserHistory } from "react-router";
import { FormControl, InputLabel } from "@material-ui/core";
import MForm from "../../../common/form-material/form";
import ButtonCustom from "../../../common/button/button-custom";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import { Loading } from "../../../common/loading/loading";
import ButtonGeneral from "../../../common/button/button-general";
import Icons from "../../../common/icons/";

const styles = {
    previewLoading: {
        position: 'absolute',
    },
    view: {
        height: 'calc(100vh - 165px)'
    },
    hiddenPreview: {
        width: 0
    }
};
class W29F3000 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataReports: [],
            dataHandOver: [],
            dataPeriod: [],
            dataForm: {
                Report: null,
                Project: null,
                HandOverID: "",
                PeriodItem: null,
                DateFrom: null,
                DateTo: null
            },
            dataCboProjects: {
                rows: [],
                total: 0
            },
            error: {
                ReportID: "",
            },
            downloadURL: "",
            downloadExt: "",
            downloadName: "",
            blobUrl: "",
            showPreview: false,

            //loading..
            loading: {},
            exportLoading: false,
            dateLoading: false,
        };
        this.loading = {};
        this.filterCboProject = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    async loadPermission() {
        await this.props.generalActions.getPermission("W29F3000", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadCboReports = () => {
        const params = {
            ReportTypeID: "D29R4090",
        };
        this._setLoading("ReportID", true);
        this.props.w29f3000Actions.getCboReports(params, (errors, data) => {
            this._setLoading("ReportID", false);
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({
                    dataReports: data
                });
            }
        });
    };

    loadCboProjects = (isReset) => {
        const { dataForm } = this.state;
        const ProjectID = dataForm.Project && dataForm.Project.ProjectID ? dataForm.Project.ProjectID : "";
        const params = {
            HostID: "",
            FormID: "W29F3000",
            Language: Config.language || "84",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch
        };
        this._setLoading("ProjectID", true);
        this.props.generalActions.getCboProjects(params, (errors, data) => {
            this._setLoading("ProjectID", false);
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    },
                    ...(!ProjectID ? { dataHandOver: [] } : {})
                });
            }
        });
    };

    loadCboHandOver = () => {
        const { dataForm } = this.state;
        const ProjectID = dataForm.Project && dataForm.Project.ProjectID ? dataForm.Project.ProjectID : "";
        if (ProjectID) {
            const params = {
                ProjectID: ProjectID,
            };
            this._setLoading("HandOverID", true);
            this.props.w29f3000Actions.getCboHandOver(params, (errors, data) => {
                this._setLoading("HandOverID", false);
                if (errors) {
                    let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if (data) {
                    this.setState({
                        dataHandOver: data
                    });
                }
            });
        }
    };

    loadCboPeriod = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
        };
        this._setLoading("PeriodItem", true);
        this.props.w29f3000Actions.getCboPeriod(params, (errors, data) => {
            this._setLoading("PeriodItem", false);
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({
                    dataPeriod: data
                });
            }
        });
    };

    loadDateData = () => {
        const { dataForm } = this.state;
        if (dataForm && dataForm.PeriodItem) {
            const params = {
                TranMonth: dataForm.PeriodItem.TranMonth ? dataForm.PeriodItem.TranMonth : 0,
                TranYear: dataForm.PeriodItem.TranYear ? dataForm.PeriodItem.TranYear : 0,
            };
            this._setLoading("DateData", true);
            this.props.w29f3000Actions.getDateData(params, (errors, data) => {
                this._setLoading("DateData", false);
                if (errors) {
                    let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if (data) {
                    const stateDate = {};
                    if (data.MinDate && moment(data.MinDate).isValid()) {
                        stateDate.DateFrom = moment(data.MinDate).format("YYYY-MM-DD");
                    }
                    if (data.MaxDate && moment(data.MaxDate).isValid()) {
                        stateDate.DateTo = moment(data.MaxDate).format("YYYY-MM-DD");
                    }
                    this._setFormData(stateDate);
                }
            });
        }
    };

    exportExcelTemplate = (isPreview) => {
        const { dataForm } = this.state;
        const ReportID = dataForm && dataForm.Report && dataForm.Report.ReportID ? dataForm.Report.ReportID : "";
        const TemplateURL = dataForm && dataForm.Report && dataForm.Report.URL ? Config.getCDNPath() + dataForm.Report.URL : "";
        //validate form...
        const validateRules = [
            {
                name: "ReportID",
                rules: "isRequired",
                value: ReportID
            },
            {
                name: "PeriodItem",
                rules: "isRequired",
                value: dataForm?.PeriodItem ?? null,
            },
            {
                name: "DateFrom",
                rules: "isRequired",
                value: dataForm.DateFrom
            },
            {
                name: "DateTo",
                rules: "isRequired",
                value: dataForm.DateTo
            }
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            const filters = [
                { id: "ReportID", type: "VarChar", value: ReportID },
                { id: "UserID", type: "VarChar", value: Config.profile.UserID || "" },
                { id: "Language", type: "Varchar", value: "84" },
                { id: "DateFrom", type: "DATETIME", value: dataForm && dataForm.DateFrom ? dataForm.DateFrom : null },
                { id: "DateTo", type: "DATETIME", value: dataForm && dataForm.DateTo ? dataForm.DateTo : null },
                { id: "ReportTypeID", type: "VarChar", value: "D29R4090" },
                { id: "ProjectID", type: "VarChar", value: dataForm.Project && dataForm.Project.ProjectID ? dataForm.Project.ProjectID : "" },
                { id: "ProjectName", type: "NVarChar", value: dataForm.Project && dataForm.Project.ProjectName ? dataForm.Project.ProjectName : "" },
                { id: "HandOverID", type: "VarChar", value: dataForm && dataForm.HandOverID ? dataForm.HandOverID : "" },
                {
                    id: "PeriodTime",
                    type: "VarChar",
                    value: dataForm && dataForm.PeriodItem && dataForm.PeriodItem.PeriodTime ? dataForm.PeriodItem.PeriodTime : ""
                },
            ];
            const params = {
                token: Config.token.id,
                ReportTypeID: "D29R4090",
                ReportID: ReportID,
                TemplateURL: TemplateURL,
                isPDF: 0,
                Params: JSON.stringify(filters)
            };
            this._setLoading(isPreview ? "Preview" : "Export", true);
            this.props.generalActions.exportExcelTemplate(params, (errors, data) => {
                this._setLoading(isPreview ? "Preview" : "Export", false);
                if (errors) {
                    let message = Config.lang("DHR_Loi_chua_xac_dinh");
                    switch (errors.code) {
                        case "EX008":
                            message = Config.lang("DHR_Mau_bao_cao_khong_duoc_tim_thay");
                            break;
                        case "MSQ005":
                            message = Config.lang("DHR_Du_lieu_xuat_excel_rong");
                            break;
                        case "MSQ006":
                            message = Config.lang("DHR_Truong_ngay_la_bat_buoc");
                            break;
                        case "EXP001":
                            message = Config.lang("DHR_Du_lieu_dau_vao_la_bat_buoc");
                            break;
                        default:
                            break;
                    }
                    Config.popup.show('INFO', message);
                    return false;
                }
                if (data) {
                    // const objectURL = URL.createObjectURL(data.content);
                    this.setState({
                        downloadURL: data.URL ? data.URL : "",
                        downloadExt: data.fileExt ? data.fileExt : "",
                        downloadName: data.fileName ? data.fileName : "",
                    }, () => {
                        if (!isPreview) {
                            let el = document.getElementById("linkExportExcel_1");
                            el.click();
                        } else {
                            this.setState({ showPreview: true });
                        }
                    });
                }
            });
        }
    };

    previewExcelTemplate = async () => {
        const { dataForm } = this.state;
        const ReportID = dataForm && dataForm.Report && dataForm.Report.ReportID ? dataForm.Report.ReportID : "";
        //validate form...
        const validateRules = [
            {
                name: "ReportID",
                rules: "isRequired",
                value: ReportID
            },
            {
                name: "DateFrom",
                rules: "isRequired",
                value: dataForm.DateFrom
            },
            {
                name: "DateTo",
                rules: "isRequired",
                value: dataForm.DateTo
            }
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            const filters = [
                { id: "ReportID", type: "VarChar", value: ReportID },
                { id: "UserID", type: "VarChar", value: Config.profile.UserID || "" },
                { id: "Language", type: "Varchar", value: "84" },
                { id: "DateFrom", type: "DATETIME", value: dataForm && dataForm.DateFrom ? dataForm.DateFrom : null },
                { id: "DateTo", type: "DATETIME", value: dataForm && dataForm.DateTo ? dataForm.DateTo : null },
                { id: "ReportTypeID", type: "VarChar", value: "D29R4090" },
                { id: "ProjectID", type: "VarChar", value: dataForm.Project && dataForm.Project.ProjectID ? dataForm.Project.ProjectID : "" },
                { id: "HandOverID", type: "VarChar", value: dataForm && dataForm.HandOverID ? dataForm.HandOverID : "" },
                {
                    id: "PeriodTime",
                    type: "VarChar",
                    value: dataForm && dataForm.PeriodItem && dataForm.PeriodItem.PeriodTime ? dataForm.PeriodItem.PeriodTime : ""
                },
            ];
            let url = Config.env.api + "/export/preview-excel-report?";
            const params = {
                token: Config.token.id,
                ReportTypeID: "D29R4090",
                ReportID: ReportID,
                isPDF: 0,
                Params: JSON.stringify(filters)
            };

            let param = Object.keys(params).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
            }).join('&');

            url = url + param;
            this.setState({
                showPreview: false
            }, () => {
                this.setState({
                    downloadURL: url,
                    showPreview: true
                }, () => {
                    this._setLoading("Preview", true);
                });
            });

        }
    };

    getInfo = (flag) => {
        const { location } = this.props;
        if (location && location.state) {
            return {
                mode: location.state.mode ? location.state.mode : 'add',
                AttendanceVoucherID: location.state.AttendanceVoucherID
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W29F2005");
                return null;
            }
            return false;
        }
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadCboReports();
        this.loadCboProjects();
        this.loadCboHandOver();
        this.loadCboPeriod();
    };

    _setLoading = (key, value, cb) => {
        if (!key) return false;
        if (typeof key === "object") {
            this.loading = {
                ...this.loading,
                ...key
            };
        } else {
            this.loading[key] = value;
        }
        this.setState({
            loading: {
                ...this.loading
            }
        }, () => {
            if (cb) cb();
        });
    };

    _setFormData = (key, value, cb) => {
        if (!key) return false;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                ...(typeof key === "object" ? key : { [key]: value })
            }
        }, () => {
            if (cb) cb();
        });
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    handleChange = (key, e) => {
        if (!key) return false;
        switch (key) {
            case "Report":
                this._setFormData(key, e.value);
                break;
            case "ProjectID":
                this._setFormData("Project", e.value, () => {
                    this.loadCboHandOver();
                });
                break;
            case "HandOverID":
                this._setFormData(key, e.value);
                break;
            case "PeriodItem":
                this._setFormData(key, e.value, () => {
                    this.loadDateData();
                });
                break;
            case "DateFrom":
                this._setFormData(key, e.value);
                break;
            case "DateTo":
                this._setFormData(key, e.value);
                break;
            default:
                this._setFormData(key, e.value);
                break;
        }
    };

    onClosePreview = () => {
        this.loading = {};
        this.setState({ showPreview: false, loading: this.loading });
    };

    loadedOnPreview = () => {
        this._setLoading("Preview", false);
    };

    render() {
        const disabled = false;
        const { classes } = this.props;
        const { loading, error, showPreview, iPermission,
            dataReports, dataHandOver, dataPeriod, dataForm,
            dataCboProjects
        } = this.state;
        if (!iPermission) return null;

        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Bao_cao_cong")}
                        onBack={this.onBack}
                    />
                </FormGroup>
                <Row className={"display_row align-start valign-middle"}>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <FormGroup>
                            <Combo
                                error={error && error["ReportID"]}
                                dataSource={dataReports}
                                displayExpr={'Title'}
                                margin={"normal"}
                                placeholder={Config.lang('DHR_Chon_mau_bao_cao')}
                                stylingMode={'underlined'}
                                disabled={loading.ReportID || disabled}
                                value={dataForm.Report}
                                shrink={true}
                                required={true}
                                label={Config.lang('DHR_Mau_bao_cao')}
                                onValueChanged={(e) => this.handleChange("Report", e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Combo.LoadMore
                                error={error && error['ProjectID']}
                                dataSource={dataCboProjects}
                                skip={this.filterCboProject.skip}
                                limit={this.filterCboProject.limit}
                                displayExpr={'ProjectName'}
                                keyExpr={'ProjectID'}
                                valueExpr={'ProjectID'}
                                value={dataForm.Project}
                                loading={loading.ProjectID}
                                stylingMode={'underlined'}
                                showClearButton={true}
                                placeholder={Config.lang('DHR_Chon_du_an')}
                                label={Config.lang('DHR_Du_an')}
                                shrink={true}
                                margin={"normal"}
                                itemRender={(e) => {
                                    const { data } = e;
                                    if (!data) return null;
                                    return data.ProjectID + ' - ' + data.ProjectName;
                                }}
                                // showCheckBoxMode={"none"}
                                onValueChanged={(e) => this.handleChange("ProjectID", e)}
                                onInputChanged={(e) => {
                                    this.filterCboProject.strSearch = e.target.value;
                                    this.filterCboProject.skip = 0;
                                    this.loadCboProjects(true);
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboProject.skip = e.skip;
                                    this.filterCboProject.limit = e.limit;
                                    this.loadCboProjects();
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Combo
                                dataSource={dataHandOver}
                                displayExpr={'HandOverName'}
                                valueExpr={'HandOverID'}
                                margin={"normal"}
                                stylingMode={'underlined'}
                                disabled={loading.HandOverID || disabled}
                                value={dataForm.HandOverID}
                                shrink={true}
                                placeholder={Config.lang('DHR_Chon_ban_giao')}
                                label={Config.lang('DHR_Ban_giao')}
                                onValueChanged={(e) => this.handleChange("HandOverID", e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <Combo
                                        dataSource={dataPeriod}
                                        displayExpr={'PeriodTime'}
                                        margin={"normal"}
                                        stylingMode={'underlined'}
                                        disabled={loading.PeriodItem || disabled}
                                        value={dataForm.PeriodItem}
                                        shrink={true}
                                        placeholder={Config.lang('DHR_Chon_ky_luong')}
                                        label={Config.lang('DHR_Ky_luong')}
                                        onValueChanged={(e) => this.handleChange("PeriodItem", e)}
                                        required
                                        error={error && error['PeriodItem']}
                                    />
                                </Col>
                                <Col xs={12} sm={8} md={8} lg={8}>
                                    <FormControl variant={"standard"}
                                        margin={"normal"}
                                        fullWidth={true}>
                                        <InputLabel
                                            shrink={true}
                                            required={true}
                                            error={!!error && !disabled && !(dataForm.DateFrom && dataForm.DateTo) && (error['DateFrom'] || error['DateTo'])}
                                        >
                                            {Config.lang("DHR_Ngay_cong")}
                                        </InputLabel>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <DateBoxPicker error={error && error['DateFrom']}
                                                    stylingMode={"underlined"}
                                                    margin={"normal"}
                                                    shrink={true}
                                                    width={"100%"}
                                                    showClearButton
                                                    max={dataForm.DateTo}
                                                    value={dataForm.DateFrom}
                                                    placeholder={"DD/MM/YYYY"}
                                                    disabled={loading.DateData || disabled}
                                                    onValueChanged={(e) => this.handleChange('DateFrom', e)}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <DateBoxPicker error={error && error['DateTo']}
                                                    stylingMode={"underlined"}
                                                    margin={"normal"}
                                                    shrink={true}
                                                    width={"100%"}
                                                    showClearButton
                                                    min={dataForm.DateFrom}
                                                    value={dataForm.DateTo}
                                                    placeholder={"DD/MM/YYYY"}
                                                    disabled={loading.DateData || disabled}
                                                    onValueChanged={(e) => this.handleChange('DateTo', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormControl>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <div className={"display_row align-center valign-bottom"}>
                                <ButtonGeneral name={Config.lang('DHR_Xuat_excel')}
                                    // icon={<InlineSVG src={require('../../../common/button/icons/excel.svg')} />}
                                    typeButton={"excel"}
                                    color={"primary"}
                                    className={"mgr10"}
                                    variant={"custom"}
                                    loading={loading.Export}
                                    disabled={loading.Preview}
                                    style={{ textTransform: 'uppercase' }}
                                    size={"large"}
                                    onClick={this.exportExcelTemplate} />
                                <ButtonCustom name={Config.lang('DHR_Xem_truoc')}
                                    color={"primary"}
                                    variant={"custom"}
                                    icon={<Icons src={require('../../../common/button/icons/preview.svg')} width={14} height={14} />}
                                    loading={loading.Preview}
                                    disabled={loading.Export}
                                    style={{ textTransform: 'uppercase' }}
                                    size={"large"}
                                    onClick={() => this.previewExcelTemplate()} />
                            </div>
                        </FormGroup>
                    </Col>
                    {showPreview && <Col xs={12} sm={12} md={6} lg={6}>
                        <div className={"display_row align-center valign-bottom"}>
                            <IconButton aria-label="close" size={"small"} onClick={this.onClosePreview}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className={"display_row align-center valign-middle"}>
                            {loading.Preview && <Loading className={classes.previewLoading} />}
                            <iframe src={this.state.downloadURL ? "https://docs.google.com/gview?url=" + encodeURIComponent(this.state.downloadURL) + "&embedded=true" : ""}
                                title={this.state.downloadName}
                                onLoad={this.loadedOnPreview}
                                className={classes.view}
                                width={'100%'} />
                        </div>
                    </Col>}
                </Row>
                <a id="linkExportExcel_1" className="hide" download={this.state.downloadName}
                    href={this.state.downloadURL}>File</a>
            </React.Fragment>
        )
    }
}

export default compose(connect((state) => ({
    getCboProjects: state.general.getCboProjects,
}), (dispatch) => ({
    w29f3000Actions: bindActionCreators(W29F3000Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W29F3000);
